unit WEBLib.JSZip;

interface

uses
  Classes, SysUtils, WEBLib.Controls, js, web, libjszip;

const
  zetZipOpen     = 1;
  zetZipDownload = 2;
  zetFileMissing = 3;

type
  TZipReadStringProcedure = reference to procedure(AFileName, AData: string);
  TZipReadArrayBufferProcedure = reference to procedure(AData: TJSArrayBufferRecord);
  TZipReadBytesProcedure = reference to procedure(AData: TBytes);

  TZipErrorEvent = procedure(Sender: TObject; AErrorCode: Integer; ADetail: string) of object;
  TZipProgressEvent = procedure(Sender: TObject; APercent: Single; ACurrentFile: string) of object;

  TZipCompression = (zcStore, zcDeflateLevel1, zcDeflateLevel2, zcDeflateLevel3,
    zcDeflateLevel4, zcDeflateLevel5, zcDeflateLevel6, zcDeflateLevel7, zcDeflateLevel8,
    zcDeflateLevel9);

  TZip = class(TComponent)
  private
    FZIP: TJSZip;
    FOnError: TZipErrorEvent;
    FOnZipLoaded: TNotifyEvent;
    FOnProgress: TZipProgressEvent;
    function GetFileName(Index: Integer): string;
    function GetFileCount: Integer;
    function GetFileComment(Index: Integer): string;
    procedure SetFileComment(Index: Integer; const Value: string);
    function GetFileNames: TStrings;
  protected
    function GetJSZipFiles: TJSZipObjects;
    procedure SetCompression(var AObject: TJSZipGenerateOptions; ALevel: Integer);
  public
    constructor Create(AOwner: TComponent); override;
    procedure Add(AFileName: string; AData: string; ABase64: Boolean = False); overload;
    procedure Add(AFileName: string; AData: TJSArrayBufferRecord); overload;
    procedure Add(AFileName: string; AData: TBytes); overload;
    procedure Open(AZipFile: string; ABase64: Boolean = False); overload;
    procedure Open(AZipFile: TJSArrayBufferRecord); overload;
    procedure Open(AZipFile: TBytes); overload;
    procedure ReadAsString(AFileName: string; AProc: TZipReadStringProcedure; ABase64: Boolean = False);
    procedure ReadAsArrayBuffer(AFileName: string; AProc: TZipReadArrayBufferProcedure);
    procedure ReadAsBytes(AFileName: string; AProc: TZipReadBytesProcedure);
    procedure Remove(AFileName: string);
    procedure RemoveAll;
    procedure Download(AZipFileName: string; ACompression: TZipCompression = zcDeflateLevel6);
    property FileName[Index: Integer]: string read GetFileName;
    property FileNames: TStrings read GetFileNames;
    property FileComment[Index: Integer]: string read GetFileComment write SetFileComment;
    property FileCount: Integer read GetFileCount;
  published
    property OnError: TZipErrorEvent read FOnError write FOnError;
    property OnProgress: TZipProgressEvent read FOnProgress write FOnProgress;
    property OnZipLoaded: TNotifyEvent read FOnZipLoaded write FOnZipLoaded;
  end;

  TWebZip = class(TZip);

implementation

{ TZip }

procedure TZip.Add(AFileName, AData: string; ABase64: Boolean);
begin
  if ABase64 then
    FZip.&file(AFileName, AData, new(['base64', True]))
  else
    FZIP.&file(AFileName, AData);
end;

procedure TZip.Add(AFileName: string; AData: TJSArrayBufferRecord);
begin
  FZIP.&file(AFileName, AData.jsarraybuffer);
end;

procedure TZip.Add(AFileName: string; AData: TBytes);
begin
  FZIP.&file(AFileName, AData);
end;

constructor TZip.Create(AOwner: TComponent);
begin
  inherited;
  FZIP := TJSZip.new;
end;

function TZip.GetFileComment(Index: Integer): string;
var
  files: TJSZipObjects;
begin
  files := GetJSZipFiles;

  if Length(files) <= Index then
    raise Exception.CreateFmt('Index %d out of bounds', [Index]);

  Result := files[Index].comment;
end;

function TZip.GetFileCount: Integer;
var
  files: TJSZipObjects;
begin
  files := GetJSZipFiles;
  Result := Length(files);
end;

function TZip.GetFileName(Index: Integer): string;
var
  files: TJSZipObjects;
begin
  files := GetJSZipFiles;

  if Length(files) <= Index then
    raise Exception.CreateFmt('Index %d out of bounds', [Index]);

  Result := files[Index].name;
end;

function TZip.GetFileNames: TStrings;
var
  files: TJSZipObjects;
  I: Integer;
begin
  Result := TStringList.Create;
  files := GetJSZipFiles;
  for I := 0 to Length(files) - 1 do
    Result.Add(files[I].name);
end;

function TZip.GetJSZipFiles: TJSZipObjects;
var
  files: TJSZipObjects;
  I: Integer;
begin
  SetLength(files, 0);
  asm
    files = Object.keys(this.FZIP.files).map(ind => this.FZIP.files[ind]);
  end;
  for I := Length(files) - 1 downto 0 do
  begin
    if files[I].dir then
      TJSArray(files).splice(I, 1);
  end;
  Result := files;
end;

procedure TZip.Open(AZipFile: string; ABase64: Boolean);
var
  aPromise: TJSPromise;
begin
  FZIP := TJSZip.new;
  if ABase64 then
    aPromise := FZIP.loadAsync(AZipFile, new(['base64', True]))
  else
    aPromise := FZIP.loadAsync(AZipFile);

  aPromise._then(function (AZip: JSValue): JSValue
  begin
    if Assigned(OnZipLoaded) then
      OnZipLoaded(Self);
  end,
  function (AErr: JSValue): JSValue
  begin
    if Assigned(OnError) then
      OnError(Self, zetZipOpen, TJSError(AErr).message);
  end);
end;

procedure TZip.Open(AZipFile: TJSArrayBufferRecord);
begin
  FZIP := TJSZip.new;
  FZIP.loadAsync(AZipFile.jsarraybuffer)._then(function (AZip: JSValue): JSValue
  begin
    if Assigned(OnZipLoaded) then
      OnZipLoaded(Self);
  end,
  function (AErr: JSValue): JSValue
  begin
    if Assigned(OnError) then
      OnError(Self, zetZipOpen, TJSError(AErr).message);
  end);
end;

procedure TZip.Open(AZipFile: TBytes);
begin
  FZIP := TJSZip.new;
  FZIP.loadAsync(AZipFile)._then(function (AZip: JSValue): JSValue
  begin
    if Assigned(OnZipLoaded) then
      OnZipLoaded(Self);
  end,
  function (AErr: JSValue): JSValue
  begin
    if Assigned(OnError) then
      OnError(Self, zetZipOpen, TJSError(AErr).message);
  end);
end;

procedure TZip.ReadAsArrayBuffer(AFileName: string;
  AProc: TZipReadArrayBufferProcedure);
var
  f: TJSZipObject;
begin
  f := FZIP.&file(AFileName);
  if Assigned(f) then
  begin
    f.async('arraybuffer')._then(function (AData: JSValue): JSValue
    var
      rec: TJSArrayBufferRecord;
    begin
      rec.jsarraybuffer := TJSArrayBuffer(AData);
      AProc(rec);
    end);
  end
  else
  begin
    if Assigned(OnError) then
      OnError(Self, zetFileMissing, AFileName);
  end;
end;

procedure TZip.ReadAsBytes(AFileName: string;
  AProc: TZipReadBytesProcedure);
var
  f: TJSZipObject;
begin
  f := FZIP.&file(AFileName);
  if Assigned(f) then
  begin
    f.async('array')._then(function (AData: JSValue): JSValue
    var
      arr: TJSArray;
      b: TBytes;
      I: Integer;
    begin
      arr := JS.toArray(AData);
      SetLength(b, arr.Length);
      for I := 0 to arr.Length - 1 do
        b[I] := Byte(arr[I]);
      AProc(b);
    end);
  end
  else
  begin
    if Assigned(OnError) then
      OnError(Self, zetFileMissing, AFileName);
  end;
end;

procedure TZip.ReadAsString(AFileName: string;
  AProc: TZipReadStringProcedure; ABase64: Boolean);
var
  f: TJSZipObject;
begin
  f := FZIP.&file(AFileName);
  if Assigned(f) then
  begin
    if ABase64 then
    begin
      f.async('base64')._then(function (AData: JSValue): JSValue
      begin
        AProc(f.name, JS.toString(AData));
      end);
    end
    else
    begin
      f.async('string')._then(function (AData: JSValue): JSValue
      begin
        AProc(f.name, JS.toString(AData));
      end);
    end;
  end
  else
  begin
    if Assigned(OnError) then
      OnError(Self, zetFileMissing, AFileName);
  end;
end;

procedure TZip.Remove(AFileName: string);
begin
  FZIP.remove(AFileName);
end;

procedure TZip.RemoveAll;
begin
  FZip := TJSZip.new;
end;

procedure TZip.Download(AZipFileName: string; ACompression: TZipCompression);
var
  opt: TJSZipGenerateOptions;
begin
  opt := TJSZipGenerateOptions.new;
  opt.&type := 'blob';
  case ACompression of
    zcStore: opt.compression := 'STORE';
    zcDeflateLevel1: SetCompression(opt, 1);
    zcDeflateLevel2: SetCompression(opt, 2);
    zcDeflateLevel3: SetCompression(opt, 3);
    zcDeflateLevel4: SetCompression(opt, 4);
    zcDeflateLevel5: SetCompression(opt, 5);
    zcDeflateLevel6: SetCompression(opt, 6);
    zcDeflateLevel7: SetCompression(opt, 7);
    zcDeflateLevel8: SetCompression(opt, 8);
    zcDeflateLevel9: SetCompression(opt, 9);
  end;
  FZIP.generateAsync(opt, procedure (AMetadata: TJSZipGenerateMetadata)
  var
    cf: string;
  begin
    cf := AMetadata.currentFile;
    if not Assigned(cf) then
      cf := '';
    if Assigned(OnProgress) then
      OnProgress(Self, AMetadata.percent, cf);
  end)._then(function (ABlob: JSValue): JSValue
  begin
    saveAs(TJSBlob(ABlob), AZipFileName);
  end,
  function (AErr: JSValue): JSValue
  begin
    console.log(AErr);
    if Assigned(OnError) then
      OnError(Self, zetZipDownload, TJSError(AErr).message);
  end);
end;

procedure TZip.SetCompression(var AObject: TJSZipGenerateOptions;
  ALevel: Integer);
begin
  AObject.compression := 'DEFLATE';
  AObject.compressionOptions := new(['level', ALevel]);
end;

procedure TZip.SetFileComment(Index: Integer; const Value: string);
var
  fn: string;
begin
  fn := GetfileName(Index);
  FZIP.&file(fn).comment := Value;
end;

end.
