unit libjszip;

interface

{$mode objfpc}
{$modeswitch externalclass}

uses
  Classes, js, web, sysutils;

type
  TJSZipObject = class external name 'ZipObject' (TJSObject)
    name: string;
    dir: Boolean;
    date: TJSDate;
    comment: string;
    function async(aType: string): TJSPromise;
  end;

  TJSZipObjects = array of TJSZipObject;

  TJSZipGenerateOptions = class external name 'Object' (TJSObject)
    &type: string;
    compression: string;
    compressionOptions: TJSObject;
    comment: string;
    mimeType: string;
    &platform: string;
    streamFiles: Boolean;
  end;

  TJSZipGenerateMetadata = class(TJSObject)
    percent: Single;
    currentFile: string;
  end;

  TJSZipGenerateCallback = reference to procedure(metadata: TJSZipGenerateMetadata);

  TJSZip = class external name 'JSZip' (TJSObject)
    files: TJSObject;
    comment: string;
    function folder(aName: string): TJSZip;
    function &file(aName: string; aData: string): TJSZip; external name 'file'; overload;
    function &file(aName: string; aData: string; AOptions: TJSObject): TJSZip; external name 'file'; overload;
    function &file(aName: string; aData: TJSBlob): TJSZip; external name 'file'; overload;
    function &file(aName: string; aData: TJSArrayBuffer): TJSZip; external name 'file'; overload;
    function &file(aName: string; aData: TJSUint8Array): TJSZip; external name 'file'; overload;
    function &file(aName: string; aData: TBytes): TJSZip; external name 'file'; overload;
    function &file(aName: string): TJSZipObject; overload;
    function remove(aName: string): TJSZip;
    function generateAsync(AOptions: TJSZipGenerateOptions): TJSPromise; overload;
    function generateAsync(AOptions: TJSZipGenerateOptions; ACallback: TJSZipGenerateCallback): TJSPromise; overload;
    function loadAsync(AData: string): TJSPromise; overload;
    function loadAsync(AData: string; AOptions: TJSObject): TJSPromise; overload;
    function loadAsync(AData: TJSArrayBuffer): TJSPromise; overload;
    function loadAsync(AData: TBytes): TJSPromise; overload;
  end;

procedure saveAs(blob: TJSBlob; filename: string); external name 'saveAs'; overload;
procedure saveAs(afile: TJSHTMLFile; filename: string); external name 'saveAs'; overload;
procedure saveAs(url: string; filename: string); external name 'saveAs'; overload;

implementation

end.
