unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ComCtrls, WEBLib.Buttons, WEBLib.GoogleChart,
  Vcl.Imaging.jpeg, WEBLib.ClientConnector, WEBLib.CDS, WEBLib.Slider,
  WEBLib.WebCtrls, WEBLib.JSZip, WEBLib.REST;

type
  TForm1 = class(TForm)
    Basis: TPanel;
    TopPaneel: TPanel;
    BodemPaneel: TPanel;
    RechterPaneel: TPanel;
    HoofdPaneel: TPanel;
    HoofdMenu: TPageControl;
    Diensten: TTabSheet;
    Doelgroep: TTabSheet;
    Werkwijze: TTabSheet;
    Contact: TTabSheet;
    LaatstBijgewerkt: TLabel;
    CopyRight: TLabel;
    Titel: TLabel;
    Algemeen: TTabSheet;
    AlgemeenPanel: TPanel;
    AlgemeenContainer: THTMLContainer;
    DienstenPanel: TPanel;
    DienstenContainer: THTMLContainer;
    DoelgroepPanel: TPanel;
    DoelgroepContainer: THTMLContainer;
    WerkwijzePanel: TPanel;
    ContactPaneel: TPanel;
    WerkwijzeContainer: THTMLContainer;
    ContactContainer: THTMLContainer;
    Plattegrond: TImageControl;
    Zeelandbrug: TImageControl;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    Download: TTabSheet;
    DownloadPaneel: TPanel;
    DownloadAppContainer: THTMLContainer;
    DownloadProgContainer: THTMLContainer;
    BezoekYouless: TLinkLabel;
    ScreenshotApp: TLinkLabel;
    DownloadApp: TLinkLabel;
    ScreenshotProg: TLinkLabel;
    DownloadLink: TLinkLabel;
    WebLabel12: TLabel;
    Inloggen: TButton;
    Inlogcode: TEdit;
    Controle: TButton;
    WebTimer: TTimer;
    Test: TLabel;
    WebMemo: TMemo;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    Testen: TButton;
    WebClientConnection1: TClientConnection;
    WebLinkLabel1: TLinkLabel;
    WebLabel15: TLabel;
    WebLabel16: TLabel;
    Historie: TTabSheet;
    HistoriePaneel: TPanel;
    ContainerApp: THTMLContainer;
    ContainerWindows: THTMLContainer;
    WebLabel17: TLabel;
    WebLabel18: TLabel;
    Screenshots: TTabSheet;
    ScreenshotPaneel: TPanel;
    ImageSlider: TImageSlider;
    SoftwareLBL: TLabel;
    SoftwareKiezer: TComboBox;
    LaadFotos: TButton;
    WebLabel19: TLabel;
    WebLabel20: TLabel;
    WebLabel21: TLabel;    procedure WebFormCreate(Sender: TObject);
    procedure InloggenClick(Sender: TObject);
    procedure ControleClick(Sender: TObject);
    procedure DownloadLinkClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure TestenClick(Sender: TObject);
    procedure LaadFotosClick(Sender: TObject);
    procedure SoftwareKiezerChange(Sender: TObject);
    procedure HoofdMenuChange(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.WebFormCreate(Sender: TObject);
begin
  If (Form1.Width > 1200) Then
  Basis.Left := (Form1.Width - Basis.Width) Div 2 Else
  Basis.Left := 0;

  //Op een smartphone wordt er door het kantelen een lege ruimte gemaakt onder de TabControl
  //Dat is nu een beetje ondervangen met onderstaande code...
  HoofdMenu.BeginUpdate;
  HoofdMenu.Height := RechterPaneel.Height;
  HoofdMenu.EndUpdate;
  HoofdMenu.TabIndex := 0;

  SoftwareKiezer.Items.Clear;
  SoftwareKiezer.Items.Add('Android app');
  SoftwareKiezer.Items.Add('Windows applicatie');
  SoftwareKiezer.ItemIndex := 0;
  //SoftwareKiezerChange(Self);

End;

procedure TForm1.ControleClick(Sender: TObject);
Var Teller, Wisser, zoeker, Regel: Integer;
    Gevonden: Boolean;
begin
 WebMemo.Lines.Clear;

 try
  WebMemo.Lines.LoadFromFile('Klanten.txt');
 finally
 end;

 For Wisser := WebMemo.Lines.Count - 1 DownTo 0 Do If WebMemo.Lines[Wisser] = '' Then WebMemo.Lines.Delete(Wisser);

 //Controleren op geregistreerde gebruiker...
 Teller := 0;
 WebTimer.Enabled := True;

 Gevonden := False;
 Regel := -1;
 If (Inlogcode.Text <> '') And (WebMemo.Lines.Count > 0) Then
 Begin
  For zoeker := 0 To WebMemo.Lines.Count - 1 Do
  Begin
   If Pos(Copy(WebMemo.Lines[zoeker], 1, 50), Inlogcode.Text) <> 0 Then Begin Gevonden := True; Regel := zoeker; End;
  End;
  If (Regel <> -1) And (Gevonden = True) Then 
  Begin
   DownloadLink.Caption := '<a href="' + Copy(WebMemo.Lines[Regel], 54, Length(WebMemo.Lines[Regel]) - 53) + '">Download de Applicatie</a>';
   DownloadLink.Visible := True;
   Webtimer.Enabled := False;
   WebTimer.Interval := 1000;
  End
  Else
   Begin
   Inc(Teller);
   WebTimer.Interval := Teller * Teller * 1000;
   Inlogcode.Visible := False;
   Inlogcode.Text := '';
   Controle.Visible := False;
   DownloadLink.Visible := False;
  End;
 End
 Else
 Begin
  Inlogcode.Visible := False;
  Inlogcode.Text := '';
  Controle.Visible := False;
  DownloadLink.Visible := False;
 End;

end;

procedure TForm1.DownloadLinkClick(Sender: TObject);
begin
  Inlogcode.Visible := False;
  Controle.Visible := False;
  Inlogcode.Text := '';
  DownloadLink.Visible := False;
end;

procedure TForm1.HoofdMenuChange(Sender: TObject);
begin
 If HoofdMenu.TabIndex = 7 Then LaadFotosClick(Self);
end;

procedure TForm1.InloggenClick(Sender: TObject);
begin
 Inlogcode.SetFocus;
 Inlogcode.Visible := True;
 Controle.Visible := True;
end;

procedure TForm1.LaadFotosClick(Sender: TObject);
Var i, Aantal, Plek: Integer;
    Categor: String;
    EenLege: Boolean;
Begin

 Case SoftwareKiezer.ItemIndex Of
 0  : Categor := 'AndroidApp';
 1  : Categor := 'WindowsApp';
 End; {Case}

 ImageSlider.ImageURLs.Clear;

 Case SoftwareKiezer.ItemIndex Of
 0 : Begin //AndroidApp
      ImageSlider.Width := 400;
      ImageSlider.Height := 680;
      ImageSlider.Left := 340;
      ImageSlider.Top := 20;
     End;
 1 : Begin //WindowsApp
      ImageSlider.Width := 920;
      ImageSlider.Height := 600;
      ImageSlider.Left := 10;
      ImageSlider.Top := 80;
     End;
 End; {Case}

 Case SoftwareKiezer.ItemIndex Of
 0 : For i := 1 To 14 Do ImageSlider.ImageURLs.Add(Format('./Fotos/' + Categor + '/' + Categor + '-%d.jpg', [i]));
 1 : For i := 1 To 9 Do ImageSlider.ImageURLs.Add(Format('./Fotos/' + Categor + '/' + Categor + '-%d.jpg', [i]));
 End; {Case}

 ImageSlider.RefreshImages;
 //EnableProperButtons;
 ImageSlider.Appearance.Buttons.Visible := True;
end;

procedure TForm1.SoftwareKiezerChange(Sender: TObject);
begin
 LaadFotosClick(Self);
 //ImageSlider.Appearance.TransitionEffect := TTransitionEffect(SoftwareKiezer.ItemIndex);
 //EnableProperButtons;
end;

procedure TForm1.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 //
end;

procedure TForm1.TestenClick(Sender: TObject);
begin
 //WebSQLRestConnection.Active := True;

// Basics/Dataset

   // empty dataset for testing https://download.tmssoftware.com/tmsweb/fishfacti2_empty.json
{
  WebClientConnection1.URI := 'https://download.tmssoftware.com/tmsweb/fishfacti.json';
  WebClientConnection1.DataNode := 'ROW';
}
//  WebClientDataSet1.FieldDefs.Clear;
//  WebClientDataSet1.FieldDefs.Add('_Species_No',ftString,0);
//  WebClientDataSet1.FieldDefs.Add('_Category',ftstring,50);
//  WebClientDataSet1.FieldDefs.Add('_Common_Name',ftstring,50);
//  WebClientDataSet1.FieldDefs.Add('_Species_Name',ftstring,50);
//  WebClientDataSet1.FieldDefs.Add('_Length__cm_',ftInteger,0);
//  WebClientDataSet1.FieldDefs.Add('_Length_In',ftString,30);
//  WebClientDataSet1.FieldDefs.Add('_Notes',ftString,255);
{
  WebClientConnection1.Active := true;
  WebButton1.Enabled := false;
}

end;

{

Examples  Services/Simple

procedure TForm1.WebButton1Click(Sender: TObject);
begin
  WebHttpRequest1.URL := 'https://download.tmssoftware.com/tmsweb/music.json';

  WebHttpRequest1.Execute(

      procedure(AResponse: string; AReq: TJSXMLHttpRequest)
      var
        js: TJSON;
        ja: TJSONArray;
        jo: TJSONObject;
        i: integer;
      begin
        js := TJSON.Create;

        try
          ja := TJSONArray(js.Parse(AResponse));

          ShowMessage('Retrieved items:' +inttostr(ja.Count));

          for i := 0 to ja.Count - 1 do
          begin
            jo := TJSONObject(ja.Items[i]);
            WebListBox1.Items.Add(jo.GetJSONValue('title'));
          end;
        finally
          js.Free;
        end;
      end
  );
end;

}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Basis := TPanel.Create(Self);
  TopPaneel := TPanel.Create(Self);
  Titel := TLabel.Create(Self);
  Zeelandbrug := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  BodemPaneel := TPanel.Create(Self);
  CopyRight := TLabel.Create(Self);
  WebMemo := TMemo.Create(Self);
  RechterPaneel := TPanel.Create(Self);
  LaatstBijgewerkt := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  Testen := TButton.Create(Self);
  HoofdPaneel := TPanel.Create(Self);
  HoofdMenu := TPageControl.Create('PageControl');
  Algemeen := TTabSheet.Create(Self);
  AlgemeenPanel := TPanel.Create(Self);
  AlgemeenContainer := THTMLContainer.Create(Self);
  Diensten := TTabSheet.Create(Self);
  DienstenPanel := TPanel.Create(Self);
  DienstenContainer := THTMLContainer.Create(Self);
  Doelgroep := TTabSheet.Create(Self);
  DoelgroepPanel := TPanel.Create(Self);
  DoelgroepContainer := THTMLContainer.Create(Self);
  Werkwijze := TTabSheet.Create(Self);
  WerkwijzePanel := TPanel.Create(Self);
  WerkwijzeContainer := THTMLContainer.Create(Self);
  Contact := TTabSheet.Create(Self);
  ContactPaneel := TPanel.Create(Self);
  Plattegrond := TImageControl.Create(Self);
  ContactContainer := THTMLContainer.Create(Self);
  Download := TTabSheet.Create(Self);
  DownloadPaneel := TPanel.Create(Self);
  BezoekYouless := TLinkLabel.Create(Self);
  ScreenshotApp := TLinkLabel.Create(Self);
  DownloadApp := TLinkLabel.Create(Self);
  ScreenshotProg := TLinkLabel.Create(Self);
  DownloadLink := TLinkLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  Test := TLabel.Create(Self);
  WebLinkLabel1 := TLinkLabel.Create(Self);
  DownloadAppContainer := THTMLContainer.Create(Self);
  DownloadProgContainer := THTMLContainer.Create(Self);
  Inloggen := TButton.Create(Self);
  Inlogcode := TEdit.Create(Self);
  Controle := TButton.Create(Self);
  Historie := TTabSheet.Create(Self);
  HistoriePaneel := TPanel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  ContainerApp := THTMLContainer.Create(Self);
  ContainerWindows := THTMLContainer.Create(Self);
  Screenshots := TTabSheet.Create(Self);
  ScreenshotPaneel := TPanel.Create(Self);
  SoftwareLBL := TLabel.Create(Self);
  ImageSlider := TImageSlider.Create(Self);
  SoftwareKiezer := TComboBox.Create(Self);
  LaadFotos := TButton.Create(Self);
  WebTimer := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);

  Basis.BeforeLoadDFMValues;
  TopPaneel.BeforeLoadDFMValues;
  Titel.BeforeLoadDFMValues;
  Zeelandbrug.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  BodemPaneel.BeforeLoadDFMValues;
  CopyRight.BeforeLoadDFMValues;
  WebMemo.BeforeLoadDFMValues;
  RechterPaneel.BeforeLoadDFMValues;
  LaatstBijgewerkt.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  Testen.BeforeLoadDFMValues;
  HoofdPaneel.BeforeLoadDFMValues;
  HoofdMenu.BeforeLoadDFMValues;
  Algemeen.BeforeLoadDFMValues;
  AlgemeenPanel.BeforeLoadDFMValues;
  AlgemeenContainer.BeforeLoadDFMValues;
  Diensten.BeforeLoadDFMValues;
  DienstenPanel.BeforeLoadDFMValues;
  DienstenContainer.BeforeLoadDFMValues;
  Doelgroep.BeforeLoadDFMValues;
  DoelgroepPanel.BeforeLoadDFMValues;
  DoelgroepContainer.BeforeLoadDFMValues;
  Werkwijze.BeforeLoadDFMValues;
  WerkwijzePanel.BeforeLoadDFMValues;
  WerkwijzeContainer.BeforeLoadDFMValues;
  Contact.BeforeLoadDFMValues;
  ContactPaneel.BeforeLoadDFMValues;
  Plattegrond.BeforeLoadDFMValues;
  ContactContainer.BeforeLoadDFMValues;
  Download.BeforeLoadDFMValues;
  DownloadPaneel.BeforeLoadDFMValues;
  BezoekYouless.BeforeLoadDFMValues;
  ScreenshotApp.BeforeLoadDFMValues;
  DownloadApp.BeforeLoadDFMValues;
  ScreenshotProg.BeforeLoadDFMValues;
  DownloadLink.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  Test.BeforeLoadDFMValues;
  WebLinkLabel1.BeforeLoadDFMValues;
  DownloadAppContainer.BeforeLoadDFMValues;
  DownloadProgContainer.BeforeLoadDFMValues;
  Inloggen.BeforeLoadDFMValues;
  Inlogcode.BeforeLoadDFMValues;
  Controle.BeforeLoadDFMValues;
  Historie.BeforeLoadDFMValues;
  HistoriePaneel.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  ContainerApp.BeforeLoadDFMValues;
  ContainerWindows.BeforeLoadDFMValues;
  Screenshots.BeforeLoadDFMValues;
  ScreenshotPaneel.BeforeLoadDFMValues;
  SoftwareLBL.BeforeLoadDFMValues;
  ImageSlider.BeforeLoadDFMValues;
  SoftwareKiezer.BeforeLoadDFMValues;
  LaadFotos.BeforeLoadDFMValues;
  WebTimer.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 1200;
    Height := 1000;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    Basis.SetParentComponent(Self);
    Basis.Name := 'Basis';
    Basis.Left := 0;
    Basis.Top := 0;
    Basis.Width := 1200;
    Basis.Height := 1000;
    Basis.ElementClassName := 'panels';
    Basis.Color := clWhite;
    TopPaneel.SetParentComponent(Basis);
    TopPaneel.Name := 'TopPaneel';
    TopPaneel.Left := 0;
    TopPaneel.Top := 0;
    TopPaneel.Width := 1200;
    TopPaneel.Height := 180;
    TopPaneel.ElementClassName := 'panels';
    TopPaneel.Align := alTop;
    TopPaneel.ChildOrder := 1;
    TopPaneel.Color := clWhite;
    Titel.SetParentComponent(TopPaneel);
    Titel.Name := 'Titel';
    Titel.Left := 981;
    Titel.Top := 55;
    Titel.Width := 207;
    Titel.Height := 29;
    Titel.Caption := 'Pc weer op orde  ';
    Titel.Font.Charset := DEFAULT_CHARSET;
    Titel.Font.Color := clDarkslateblue;
    Titel.Font.Height := -24;
    Titel.Font.Name := 'Tahoma';
    Titel.Font.Style := [fsBold];
    Titel.HeightPercent := 100.000000000000000000;
    Titel.ParentFont := False;
    Titel.WidthPercent := 100.000000000000000000;
    Zeelandbrug.SetParentComponent(TopPaneel);
    Zeelandbrug.Name := 'Zeelandbrug';
    Zeelandbrug.Left := 0;
    Zeelandbrug.Top := -4;
    Zeelandbrug.Width := 945;
    Zeelandbrug.Height := 180;
    Zeelandbrug.HeightPercent := 100.000000000000000000;
    Zeelandbrug.WidthPercent := 100.000000000000000000;
    Zeelandbrug.ChildOrder := 2;
    Zeelandbrug.Picture.LoadFromFile('Unit1.TopPaneel.Zeelandbrug.Picture.jpg');
    WebLabel1.SetParentComponent(TopPaneel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 1010;
    WebLabel1.Top := 35;
    WebLabel1.Width := 21;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'uw';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clDarkslateblue;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TopPaneel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 1086;
    WebLabel2.Top := 85;
    WebLabel2.Width := 69;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'onze zorg';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clDarkslateblue;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    BodemPaneel.SetParentComponent(Basis);
    BodemPaneel.Name := 'BodemPaneel';
    BodemPaneel.Left := 0;
    BodemPaneel.Top := 920;
    BodemPaneel.Width := 1200;
    BodemPaneel.Height := 80;
    BodemPaneel.ElementClassName := 'panels';
    BodemPaneel.ChildOrder := 2;
    BodemPaneel.Color := clWhite;
    CopyRight.SetParentComponent(BodemPaneel);
    CopyRight.Name := 'CopyRight';
    CopyRight.Left := 427;
    CopyRight.Top := 35;
    CopyRight.Width := 215;
    CopyRight.Height := 14;
    CopyRight.Caption := 'Copyright: Pc weer op orde (2019)';
    CopyRight.Font.Charset := DEFAULT_CHARSET;
    CopyRight.Font.Color := clDarkslateblue;
    CopyRight.Font.Height := -12;
    CopyRight.Font.Name := 'Tahoma';
    CopyRight.Font.Style := [fsBold];
    CopyRight.HeightPercent := 100.000000000000000000;
    CopyRight.ParentFont := False;
    CopyRight.WidthPercent := 100.000000000000000000;
    WebMemo.SetParentComponent(BodemPaneel);
    WebMemo.Name := 'WebMemo';
    WebMemo.Left := 648;
    WebMemo.Top := 8;
    WebMemo.Width := 546;
    WebMemo.Height := 63;
    WebMemo.HeightPercent := 100.000000000000000000;
    WebMemo.SelLength := 0;
    WebMemo.SelStart := 0;
    WebMemo.Visible := False;
    WebMemo.WidthPercent := 100.000000000000000000;
    WebMemo.WordWrap := False;
    RechterPaneel.SetParentComponent(Basis);
    RechterPaneel.Name := 'RechterPaneel';
    RechterPaneel.Left := 951;
    RechterPaneel.Top := 182;
    RechterPaneel.Width := 256;
    RechterPaneel.Height := 740;
    RechterPaneel.ElementClassName := 'panelsonderboven';
    RechterPaneel.ChildOrder := 3;
    RechterPaneel.Color := clWhite;
    LaatstBijgewerkt.SetParentComponent(RechterPaneel);
    LaatstBijgewerkt.Name := 'LaatstBijgewerkt';
    LaatstBijgewerkt.Left := 37;
    LaatstBijgewerkt.Top := 688;
    LaatstBijgewerkt.Width := 172;
    LaatstBijgewerkt.Height := 13;
    LaatstBijgewerkt.Caption := 'Laatst bijgewerkt: 05-06-2024';
    LaatstBijgewerkt.Font.Charset := DEFAULT_CHARSET;
    LaatstBijgewerkt.Font.Color := clDarkslateblue;
    LaatstBijgewerkt.Font.Height := -11;
    LaatstBijgewerkt.Font.Name := 'Tahoma';
    LaatstBijgewerkt.Font.Style := [fsBold];
    LaatstBijgewerkt.HeightPercent := 100.000000000000000000;
    LaatstBijgewerkt.ParentFont := False;
    LaatstBijgewerkt.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(RechterPaneel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 11;
    WebLabel3.Top := 31;
    WebLabel3.Width := 116;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Nieuwe dienst';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clDarkslateblue;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [fsBold,fsUnderline];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(RechterPaneel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 10;
    WebLabel4.Top := 55;
    WebLabel4.Width := 216;
    WebLabel4.Height := 14;
    WebLabel4.Caption := 'U wilt energie besparen, maar hoe?';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clDarkslateblue;
    WebLabel4.Font.Height := -12;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [fsBold];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(RechterPaneel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 11;
    WebLabel5.Top := 75;
    WebLabel5.Width := 226;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'Onze software zorgt ervoor dat u de';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clDarkslateblue;
    WebLabel5.Font.Height := -12;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [fsBold];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(RechterPaneel);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 11;
    WebLabel6.Top := 115;
    WebLabel6.Width := 197;
    WebLabel6.Height := 14;
    WebLabel6.Caption := 'in '#233#233'n oogopslag verbruik en de';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clDarkslateblue;
    WebLabel6.Font.Height := -12;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [fsBold];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(RechterPaneel);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 11;
    WebLabel7.Top := 135;
    WebLabel7.Width := 197;
    WebLabel7.Height := 14;
    WebLabel7.Caption := 'productie van uw zonnepanelen';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clDarkslateblue;
    WebLabel7.Font.Height := -12;
    WebLabel7.Font.Name := 'Tahoma';
    WebLabel7.Font.Style := [fsBold];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(RechterPaneel);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 11;
    WebLabel8.Top := 155;
    WebLabel8.Width := 88;
    WebLabel8.Height := 14;
    WebLabel8.Caption := 'kunt bekijken.';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clDarkslateblue;
    WebLabel8.Font.Height := -12;
    WebLabel8.Font.Name := 'Tahoma';
    WebLabel8.Font.Style := [fsBold];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(RechterPaneel);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 11;
    WebLabel9.Top := 175;
    WebLabel9.Width := 226;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'Via internet of thuis heeft u zo altijd';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clDarkslateblue;
    WebLabel9.Font.Height := -12;
    WebLabel9.Font.Name := 'Tahoma';
    WebLabel9.Font.Style := [fsBold];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(RechterPaneel);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 11;
    WebLabel10.Top := 195;
    WebLabel10.Width := 115;
    WebLabel10.Height := 14;
    WebLabel10.Caption := 'actuele informatie.';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clDarkslateblue;
    WebLabel10.Font.Height := -12;
    WebLabel10.Font.Name := 'Tahoma';
    WebLabel10.Font.Style := [fsBold];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(RechterPaneel);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 11;
    WebLabel11.Top := 215;
    WebLabel11.Width := 195;
    WebLabel11.Height := 14;
    WebLabel11.Caption := '(Geen abonnement meer nodig)';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clDarkslateblue;
    WebLabel11.Font.Height := -12;
    WebLabel11.Font.Name := 'Tahoma';
    WebLabel11.Font.Style := [fsBold];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(RechterPaneel);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 11;
    WebLabel13.Top := 314;
    WebLabel13.Width := 227;
    WebLabel13.Height := 14;
    WebLabel13.Caption := 'YouLess uitlezen: versie 2.40 (nieuw)';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clRed;
    WebLabel13.Font.Height := -12;
    WebLabel13.Font.Name := 'Tahoma';
    WebLabel13.Font.Style := [fsBold];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(RechterPaneel);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 10;
    WebLabel14.Top := 247;
    WebLabel14.Width := 198;
    WebLabel14.Height := 14;
    WebLabel14.Caption := 'Youless app: versie 2.00 (nieuw)';
    WebLabel14.Font.Charset := DEFAULT_CHARSET;
    WebLabel14.Font.Color := clRed;
    WebLabel14.Font.Height := -12;
    WebLabel14.Font.Name := 'Tahoma';
    WebLabel14.Font.Style := [fsBold];
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.ParentFont := False;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(RechterPaneel);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 11;
    WebLabel15.Top := 330;
    WebLabel15.Width := 215;
    WebLabel15.Height := 13;
    WebLabel15.Caption := '(Voor geregistreerde gebruikers: aanvragen ';
    WebLabel15.Font.Charset := DEFAULT_CHARSET;
    WebLabel15.Font.Color := clDarkslateblue;
    WebLabel15.Font.Height := -11;
    WebLabel15.Font.Name := 'Tahoma';
    WebLabel15.Font.Style := [];
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(RechterPaneel);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 15;
    WebLabel16.Top := 343;
    WebLabel16.Width := 51;
    WebLabel16.Height := 13;
    WebLabel16.Caption := 'per e-mail)';
    WebLabel16.Font.Charset := DEFAULT_CHARSET;
    WebLabel16.Font.Color := clDarkslateblue;
    WebLabel16.Font.Height := -11;
    WebLabel16.Font.Name := 'Tahoma';
    WebLabel16.Font.Style := [];
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel19.SetParentComponent(RechterPaneel);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 10;
    WebLabel19.Top := 264;
    WebLabel19.Width := 223;
    WebLabel19.Height := 13;
    WebLabel19.Caption := '(Nieuwe versie over de oude heen Installeren.';
    WebLabel19.Font.Charset := DEFAULT_CHARSET;
    WebLabel19.Font.Color := clDarkslateblue;
    WebLabel19.Font.Height := -11;
    WebLabel19.Font.Name := 'Tahoma';
    WebLabel19.Font.Style := [];
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.ParentFont := False;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebLabel20.SetParentComponent(RechterPaneel);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 11;
    WebLabel20.Top := 95;
    WebLabel20.Width := 217;
    WebLabel20.Height := 14;
    WebLabel20.Caption := 'meters op afstand kunt uitlezen en';
    WebLabel20.Font.Charset := DEFAULT_CHARSET;
    WebLabel20.Font.Color := clDarkslateblue;
    WebLabel20.Font.Height := -12;
    WebLabel20.Font.Name := 'Tahoma';
    WebLabel20.Font.Style := [fsBold];
    WebLabel20.HeightPercent := 100.000000000000000000;
    WebLabel20.ParentFont := False;
    WebLabel20.WidthPercent := 100.000000000000000000;
    WebLabel21.SetParentComponent(RechterPaneel);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 10;
    WebLabel21.Top := 277;
    WebLabel21.Width := 227;
    WebLabel21.Height := 13;
    WebLabel21.Caption := 'Crasht de nieuwe dan oude eerst verwijderen) ';
    WebLabel21.Font.Charset := DEFAULT_CHARSET;
    WebLabel21.Font.Color := clDarkslateblue;
    WebLabel21.Font.Height := -11;
    WebLabel21.Font.Name := 'Tahoma';
    WebLabel21.Font.Style := [];
    WebLabel21.HeightPercent := 100.000000000000000000;
    WebLabel21.ParentFont := False;
    WebLabel21.WidthPercent := 100.000000000000000000;
    Testen.SetParentComponent(RechterPaneel);
    Testen.Name := 'Testen';
    Testen.Left := 18;
    Testen.Top := 532;
    Testen.Width := 70;
    Testen.Height := 20;
    Testen.Caption := 'Testen';
    Testen.ChildOrder := 8;
    Testen.Font.Charset := DEFAULT_CHARSET;
    Testen.Font.Color := clDarkslateblue;
    Testen.Font.Height := -12;
    Testen.Font.Name := 'Tahoma';
    Testen.Font.Style := [fsBold];
    Testen.HeightPercent := 100.000000000000000000;
    Testen.ParentFont := False;
    Testen.Visible := False;
    Testen.WidthPercent := 100.000000000000000000;
    SetEvent(Testen, Self, 'OnClick', 'TestenClick');
    HoofdPaneel.SetParentComponent(Basis);
    HoofdPaneel.Name := 'HoofdPaneel';
    HoofdPaneel.Left := 0;
    HoofdPaneel.Top := 180;
    HoofdPaneel.Width := 945;
    HoofdPaneel.Height := 740;
    HoofdPaneel.ElementClassName := 'panelsrond';
    HoofdPaneel.ChildOrder := 4;
    HoofdPaneel.Color := 15924735;
    HoofdMenu.SetParentComponent(HoofdPaneel);
    HoofdMenu.Name := 'HoofdMenu';
    HoofdMenu.Left := 0;
    HoofdMenu.Top := 0;
    HoofdMenu.Width := 945;
    HoofdMenu.Height := 740;
    HoofdMenu.Align := alClient;
    HoofdMenu.BorderStyle := bsSingle;
    HoofdMenu.Font.Charset := DEFAULT_CHARSET;
    HoofdMenu.Font.Color := clDarkslateblue;
    HoofdMenu.Font.Height := -16;
    HoofdMenu.Font.Name := 'Tahoma';
    HoofdMenu.Font.Style := [fsBold];
    HoofdMenu.TabIndex := 7;
    HoofdMenu.ParentFont := False;
    HoofdMenu.SelectedColor := 15453831;
    HoofdMenu.TabOrder := 0;
    SetEvent(HoofdMenu, Self, 'OnChange', 'HoofdMenuChange');
    Algemeen.SetParentComponent(HoofdMenu);
    Algemeen.Name := 'Algemeen';
    Algemeen.Left := 0;
    Algemeen.Top := 20;
    Algemeen.Width := 945;
    Algemeen.Height := 720;
    Algemeen.Caption := ' Algemeen ';
    AlgemeenPanel.SetParentComponent(Algemeen);
    AlgemeenPanel.Name := 'AlgemeenPanel';
    AlgemeenPanel.Left := 0;
    AlgemeenPanel.Top := 0;
    AlgemeenPanel.Width := 945;
    AlgemeenPanel.Height := 720;
    AlgemeenPanel.ElementClassName := 'panels';
    AlgemeenPanel.Align := alClient;
    AlgemeenPanel.ChildOrder := 1;
    AlgemeenPanel.Color := clWhite;
    AlgemeenContainer.SetParentComponent(AlgemeenPanel);
    AlgemeenContainer.Name := 'AlgemeenContainer';
    AlgemeenContainer.Left := 58;
    AlgemeenContainer.Top := 32;
    AlgemeenContainer.Width := 833;
    AlgemeenContainer.Height := 649;
    AlgemeenContainer.HTML.BeginUpdate;
    try
      AlgemeenContainer.HTML.Clear;
      AlgemeenContainer.HTML.Add('<font size="4"><u>Wat hebben wij te bieden:</u></font><br><br>');
      AlgemeenContainer.HTML.Add('We horen vaak dat mensen vastlopen met hun computer (laptop), alles werkt vreselijk traag, de printer wil niet meer, de wifi verbinding valt weg of werkt traag of is zwak. ');
      AlgemeenContainer.HTML.Add('En veel andere soorten klachten, waarvoor iemand nodig is om de zaak weer vlot te trekken.<br><br>');
      AlgemeenContainer.HTML.Add('');
      AlgemeenContainer.HTML.Add('Soms is de pc gecrasht en zijn er bestanden of foto'#8217's verloren gegaan. Waar haal je dan de software om verloren bestanden weer te herstellen en foto'#8217's te redden. Vaak ');
      AlgemeenContainer.HTML.Add('kost dit veel tijd en waar haal je de kennis vandaan. Soms ontbreekt ook gewoon de tijd om zelf aan de slag te gaan.<br><br>');
      AlgemeenContainer.HTML.Add('');
      AlgemeenContainer.HTML.Add('Wij kunnen u daarbij helpen. Uiteraard weten wij ook niet alles, maar we hebben wel ervaring met al dit soort problemen. Wij onderzoeken uw probleem, horen uw wensen ');
      AlgemeenContainer.HTML.Add('aan en gaan aan de slag om u te helpen.<br><br>');
      AlgemeenContainer.HTML.Add('');
      AlgemeenContainer.HTML.Add('Heeft u een slimme meter en wilt u inzicht in verbruik van elektriciteit en gas of heeft u zonnepanelen, maar geen handige inzage in de opbrengst hiervan. Ook dat kunnen ');
      AlgemeenContainer.HTML.Add('wij voor u verzorgen. Daarvoor maken wij gebruik van de energiemeter van <A href="http://www.youless.nl">Youless</A> en de door ons ontwikkelde software ');
      AlgemeenContainer.HTML.Add('voor Windows. Hiermee kunt u waar ook ter wereld via internet uw verbruik en opbrengst bekijken.<br><br>');
      AlgemeenContainer.HTML.Add('');
      AlgemeenContainer.HTML.Add('Om betaalbare hulp te bieden rekenen wij niet alle uren die we er aan besteden, maar hanteren wij een redelijke vergoeding. Wij gaan op betrouwbare wijze met uw ');
      AlgemeenContainer.HTML.Add('bestanden om en respecteren uw privacy zodat er geen gegevens op straat komen te liggen.<br><br>');
      AlgemeenContainer.HTML.Add('');
      AlgemeenContainer.HTML.Add('Wij hopen u van dienst te kunnen zijn. Neem vrijblijvend contact met ons op.<br>');
    finally
      AlgemeenContainer.HTML.EndUpdate;
    end;
    Diensten.SetParentComponent(HoofdMenu);
    Diensten.Name := 'Diensten';
    Diensten.Left := 0;
    Diensten.Top := 20;
    Diensten.Width := 945;
    Diensten.Height := 720;
    Diensten.Caption := '  Diensten  ';
    DienstenPanel.SetParentComponent(Diensten);
    DienstenPanel.Name := 'DienstenPanel';
    DienstenPanel.Left := 0;
    DienstenPanel.Top := 0;
    DienstenPanel.Width := 945;
    DienstenPanel.Height := 720;
    DienstenPanel.ElementClassName := 'panels';
    DienstenPanel.Align := alClient;
    DienstenPanel.ChildOrder := 1;
    DienstenPanel.Color := clWhite;
    DienstenContainer.SetParentComponent(DienstenPanel);
    DienstenContainer.Name := 'DienstenContainer';
    DienstenContainer.Left := 56;
    DienstenContainer.Top := 32;
    DienstenContainer.Width := 833;
    DienstenContainer.Height := 649;
    DienstenContainer.HTML.BeginUpdate;
    try
      DienstenContainer.HTML.Clear;
      DienstenContainer.HTML.Add('<font size="4"><u>Wij verzorgen diensten op het gebied van:</u></font><br><br>');
      DienstenContainer.HTML.Add('<li>Trage pc of laptop. Onderzoek, advisering en oplossen.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Trage of wegvallende WiFi verbinding.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Advies en verbetering van goed werkend netwerk in en om het huis.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Geen verbinding met printer.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Crash van harddisc oplossen.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Vervangen en migreren van trage harddisc door snelle opslag SSD.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Redden van bestanden na een crash van de harddisc.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Netwerk in huis verbeteren, advies en uitvoering.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Geheugenuitbreiding, accu vervangen, toetsenbord vervangen.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Advisering over Back-up systeem.<br><br></li>');
      DienstenContainer.HTML.Add('<li>Plaatsen van energiemeter en installeren van software voor inzicht van verbruik en teruglevering.<br><br></li>');
      DienstenContainer.HTML.Add('<FONT color="#FF0000"><li>Wij ontwikkelen ook software voor diverse doeleinden.<br></li></FONT>');
    finally
      DienstenContainer.HTML.EndUpdate;
    end;
    Doelgroep.SetParentComponent(HoofdMenu);
    Doelgroep.Name := 'Doelgroep';
    Doelgroep.Left := 0;
    Doelgroep.Top := 20;
    Doelgroep.Width := 945;
    Doelgroep.Height := 720;
    Doelgroep.Caption := '  Doelgroep  ';
    DoelgroepPanel.SetParentComponent(Doelgroep);
    DoelgroepPanel.Name := 'DoelgroepPanel';
    DoelgroepPanel.Left := 0;
    DoelgroepPanel.Top := 0;
    DoelgroepPanel.Width := 945;
    DoelgroepPanel.Height := 720;
    DoelgroepPanel.ElementClassName := 'panels';
    DoelgroepPanel.Align := alClient;
    DoelgroepPanel.ChildOrder := 1;
    DoelgroepPanel.Color := clWhite;
    DoelgroepContainer.SetParentComponent(DoelgroepPanel);
    DoelgroepContainer.Name := 'DoelgroepContainer';
    DoelgroepContainer.Left := 56;
    DoelgroepContainer.Top := 32;
    DoelgroepContainer.Width := 833;
    DoelgroepContainer.Height := 649;
    DoelgroepContainer.HTML.BeginUpdate;
    try
      DoelgroepContainer.HTML.Clear;
      DoelgroepContainer.HTML.Add('<font size="4"><u>Wij bieden onze diensten aan voor de doelgroep:</u></font><br><br>');
      DoelgroepContainer.HTML.Add('<li>Particulieren in de thuissituatie.<br><br></li>');
      DoelgroepContainer.HTML.Add('<li>Ouderen die er niet meer uit komen.<br><br></li>');
      DoelgroepContainer.HTML.Add('<li>In het gebied wonen rond Goes.<br><br></li>');
      DoelgroepContainer.HTML.Add('<li>Bezitters van zonnepanelen met weing of geen inzicht in opbrengst.<br><br></li>');
      DoelgroepContainer.HTML.Add('<li>Mensen die zelf geen tijd hebben voor de problemen.<br><br></li>');
      DoelgroepContainer.HTML.Add('');
    finally
      DoelgroepContainer.HTML.EndUpdate;
    end;
    Werkwijze.SetParentComponent(HoofdMenu);
    Werkwijze.Name := 'Werkwijze';
    Werkwijze.Left := 0;
    Werkwijze.Top := 20;
    Werkwijze.Width := 945;
    Werkwijze.Height := 720;
    Werkwijze.Caption := '  Werkwijze  ';
    WerkwijzePanel.SetParentComponent(Werkwijze);
    WerkwijzePanel.Name := 'WerkwijzePanel';
    WerkwijzePanel.Left := 0;
    WerkwijzePanel.Top := 0;
    WerkwijzePanel.Width := 945;
    WerkwijzePanel.Height := 720;
    WerkwijzePanel.ElementClassName := 'panels';
    WerkwijzePanel.Align := alClient;
    WerkwijzePanel.ChildOrder := 1;
    WerkwijzePanel.Color := clWhite;
    WerkwijzeContainer.SetParentComponent(WerkwijzePanel);
    WerkwijzeContainer.Name := 'WerkwijzeContainer';
    WerkwijzeContainer.Left := 56;
    WerkwijzeContainer.Top := 32;
    WerkwijzeContainer.Width := 833;
    WerkwijzeContainer.Height := 649;
    WerkwijzeContainer.HTML.BeginUpdate;
    try
      WerkwijzeContainer.HTML.Clear;
      WerkwijzeContainer.HTML.Add('<font size="4"><u>Wij hanteren de volgende werkwijze:</u></font><br><br>');
      WerkwijzeContainer.HTML.Add('<li>U neemt (vrijblijvend) telefonisch contact met ons op en we bespreken uw probleem.<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>Wij geven aan of we uw probleem denken te kunnen oplossen.<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>Bij reparatie aan een pc of laptop gebeurt dat standaard bij ons.<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>Bij andere problemen bezoeken wij u thuis en lossen we het probleem ter plaatse op.<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>Aan het bespreken van uw probleem zijn geen kosten verbonden.<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>In geval van bezoek bij u thuis rekenen wij '#8364' 0,20 per km (heen en terug).<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>Als wij een reparatie bespreken geven wij u (zo mogelijk) een inschatting van de uren.<br><br></li>');
      WerkwijzeContainer.HTML.Add('<li>Wij gaan er van uit dat wij&nbsp;niet alles weten en zullen altijd een redelijk tarief berekenen.<br><br></li>');
      WerkwijzeContainer.HTML.Add('');
    finally
      WerkwijzeContainer.HTML.EndUpdate;
    end;
    Contact.SetParentComponent(HoofdMenu);
    Contact.Name := 'Contact';
    Contact.Left := 0;
    Contact.Top := 20;
    Contact.Width := 945;
    Contact.Height := 720;
    Contact.Caption := '  Contact  ';
    ContactPaneel.SetParentComponent(Contact);
    ContactPaneel.Name := 'ContactPaneel';
    ContactPaneel.Left := 0;
    ContactPaneel.Top := 0;
    ContactPaneel.Width := 945;
    ContactPaneel.Height := 720;
    ContactPaneel.ElementClassName := 'panels';
    ContactPaneel.Align := alClient;
    ContactPaneel.ChildOrder := 2;
    ContactPaneel.Color := clWhite;
    Plattegrond.SetParentComponent(ContactPaneel);
    Plattegrond.Name := 'Plattegrond';
    Plattegrond.Left := 328;
    Plattegrond.Top := 40;
    Plattegrond.Width := 550;
    Plattegrond.Height := 321;
    Plattegrond.HeightPercent := 100.000000000000000000;
    Plattegrond.WidthPercent := 100.000000000000000000;
    Plattegrond.AutoSize := True;
    Plattegrond.ChildOrder := 1;
    Plattegrond.Picture.LoadFromFile('Unit1.ContactPaneel.Plattegrond.Picture.jpg');
    ContactContainer.SetParentComponent(ContactPaneel);
    ContactContainer.Name := 'ContactContainer';
    ContactContainer.Left := 56;
    ContactContainer.Top := 32;
    ContactContainer.Width := 833;
    ContactContainer.Height := 649;
    ContactContainer.ChildOrder := 1;
    ContactContainer.HTML.BeginUpdate;
    try
      ContactContainer.HTML.Clear;
      ContactContainer.HTML.Add('<font size="4"><strong><u>Contact:</u></strong></font><br><br>');
      ContactContainer.HTML.Add('Elstarstraat 119<br>');
      ContactContainer.HTML.Add('4421 DV Kapelle<br><br>');
      ContactContainer.HTML.Add('Tel: 06-21645165<br>');
      ContactContainer.HTML.Add('e-mail: <a href="mailto:pcweeroporde@xs4all.nl">pcweeroporde@xs4all.nl</a><br><br>');
      ContactContainer.HTML.Add('KvK: 76385450<br>');
      ContactContainer.HTML.Add('BTW-id: NL003080885B43<br><br>');
      ContactContainer.HTML.Add('<font color="#ff0000"><u>Alleen op werkdagen.</u></font><br>');
      ContactContainer.HTML.Add('<u><font color="#ff0000">Bezoek alleen op afspraak.</font></u>');
      ContactContainer.HTML.Add('');
    finally
      ContactContainer.HTML.EndUpdate;
    end;
    Download.SetParentComponent(HoofdMenu);
    Download.Name := 'Download';
    Download.Left := 0;
    Download.Top := 20;
    Download.Width := 945;
    Download.Height := 720;
    Download.Caption := 'Downloads';
    DownloadPaneel.SetParentComponent(Download);
    DownloadPaneel.Name := 'DownloadPaneel';
    DownloadPaneel.Left := 0;
    DownloadPaneel.Top := 0;
    DownloadPaneel.Width := 945;
    DownloadPaneel.Height := 720;
    DownloadPaneel.ElementClassName := 'panels';
    DownloadPaneel.Align := alClient;
    DownloadPaneel.ChildOrder := 2;
    DownloadPaneel.Color := clWhite;
    BezoekYouless.SetParentComponent(DownloadPaneel);
    BezoekYouless.Name := 'BezoekYouless';
    BezoekYouless.Left := 664;
    BezoekYouless.Top := 69;
    BezoekYouless.Width := 97;
    BezoekYouless.Height := 14;
    BezoekYouless.Font.Charset := DEFAULT_CHARSET;
    BezoekYouless.Font.Color := clNavy;
    BezoekYouless.Font.Height := -12;
    BezoekYouless.Font.Name := 'Tahoma';
    BezoekYouless.Font.Style := [];
    BezoekYouless.ParentFont := False;
    BezoekYouless.HeightPercent := 100.000000000000000000;
    BezoekYouless.WidthPercent := 100.000000000000000000;
    BezoekYouless.Caption := '<a href="http://youless.nl">Bezoek Youless.nl</a>';
    ScreenshotApp.SetParentComponent(DownloadPaneel);
    ScreenshotApp.Name := 'ScreenshotApp';
    ScreenshotApp.Left := 664;
    ScreenshotApp.Top := 123;
    ScreenshotApp.Width := 186;
    ScreenshotApp.Height := 14;
    ScreenshotApp.Font.Charset := DEFAULT_CHARSET;
    ScreenshotApp.Font.Color := clNavy;
    ScreenshotApp.Font.Height := -12;
    ScreenshotApp.Font.Name := 'Tahoma';
    ScreenshotApp.Font.Style := [];
    ScreenshotApp.ParentFont := False;
    ScreenshotApp.HeightPercent := 100.000000000000000000;
    ScreenshotApp.WidthPercent := 100.000000000000000000;
    ScreenshotApp.Caption := '<a href="Bestanden\Screenshots YouLessSee.pdf">Screenshots en uitleg van de App</a>';
    DownloadApp.SetParentComponent(DownloadPaneel);
    DownloadApp.Name := 'DownloadApp';
    DownloadApp.Left := 664;
    DownloadApp.Top := 150;
    DownloadApp.Width := 98;
    DownloadApp.Height := 14;
    DownloadApp.Font.Charset := DEFAULT_CHARSET;
    DownloadApp.Font.Color := clNavy;
    DownloadApp.Font.Height := -12;
    DownloadApp.Font.Name := 'Tahoma';
    DownloadApp.Font.Style := [];
    DownloadApp.ParentFont := False;
    DownloadApp.HeightPercent := 100.000000000000000000;
    DownloadApp.WidthPercent := 100.000000000000000000;
    DownloadApp.Caption := '<a href="Bestanden\YoulessSee.apk">Download de App</a>';
    ScreenshotProg.SetParentComponent(DownloadPaneel);
    ScreenshotProg.Name := 'ScreenshotProg';
    ScreenshotProg.Left := 664;
    ScreenshotProg.Top := 315;
    ScreenshotProg.Width := 193;
    ScreenshotProg.Height := 14;
    ScreenshotProg.Font.Charset := DEFAULT_CHARSET;
    ScreenshotProg.Font.Color := clNavy;
    ScreenshotProg.Font.Height := -12;
    ScreenshotProg.Font.Name := 'Tahoma';
    ScreenshotProg.Font.Style := [];
    ScreenshotProg.ParentFont := False;
    ScreenshotProg.HeightPercent := 100.000000000000000000;
    ScreenshotProg.WidthPercent := 100.000000000000000000;
    ScreenshotProg.Caption := '<a href="Bestanden\Screenshots YouLess uitlezen.pdf">Screenshots & uitleg van programma</a>';
    DownloadLink.SetParentComponent(DownloadPaneel);
    DownloadLink.Name := 'DownloadLink';
    DownloadLink.Left := 664;
    DownloadLink.Top := 430;
    DownloadLink.Width := 128;
    DownloadLink.Height := 14;
    DownloadLink.Font.Charset := DEFAULT_CHARSET;
    DownloadLink.Font.Color := clNavy;
    DownloadLink.Font.Height := -12;
    DownloadLink.Font.Name := 'Tahoma';
    DownloadLink.Font.Style := [];
    DownloadLink.ParentFont := False;
    DownloadLink.Visible := False;
    DownloadLink.HeightPercent := 100.000000000000000000;
    DownloadLink.WidthPercent := 100.000000000000000000;
    SetEvent(DownloadLink, Self, 'OnClick', 'DownloadLinkClick');
    DownloadLink.Caption := '<a href="Klanten\CarstenBon\CarstenBon.zip">Download de Applicatie</a>';
    WebLabel12.SetParentComponent(DownloadPaneel);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 664;
    WebLabel12.Top := 352;
    WebLabel12.Width := 177;
    WebLabel12.Height := 14;
    WebLabel12.Caption := 'Alleen geregistreerde gebruikers';
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clDarkslateblue;
    WebLabel12.Font.Height := -12;
    WebLabel12.Font.Name := 'Tahoma';
    WebLabel12.Font.Style := [fsUnderline];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    Test.SetParentComponent(DownloadPaneel);
    Test.Name := 'Test';
    Test.Left := 672;
    Test.Top := 488;
    Test.Width := 25;
    Test.Height := 14;
    Test.Caption := 'Test';
    Test.Font.Charset := DEFAULT_CHARSET;
    Test.Font.Color := clDarkslateblue;
    Test.Font.Height := -12;
    Test.Font.Name := 'Tahoma';
    Test.Font.Style := [];
    Test.HeightPercent := 100.000000000000000000;
    Test.ParentFont := False;
    Test.Visible := False;
    Test.WidthPercent := 100.000000000000000000;
    WebLinkLabel1.SetParentComponent(DownloadPaneel);
    WebLinkLabel1.Name := 'WebLinkLabel1';
    WebLinkLabel1.Left := 664;
    WebLinkLabel1.Top := 96;
    WebLinkLabel1.Width := 107;
    WebLinkLabel1.Height := 14;
    WebLinkLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLinkLabel1.Font.Color := clNavy;
    WebLinkLabel1.Font.Height := -12;
    WebLinkLabel1.Font.Name := 'Tahoma';
    WebLinkLabel1.Font.Style := [];
    WebLinkLabel1.ParentFont := False;
    WebLinkLabel1.HeightPercent := 100.000000000000000000;
    WebLinkLabel1.WidthPercent := 100.000000000000000000;
    WebLinkLabel1.Caption := '<a href="Bestanden\Youless-LS120-Handleiding-1.42.pdf">Handleiding Youless</a>';
    DownloadAppContainer.SetParentComponent(DownloadPaneel);
    DownloadAppContainer.Name := 'DownloadAppContainer';
    DownloadAppContainer.Left := 56;
    DownloadAppContainer.Top := 32;
    DownloadAppContainer.Width := 580;
    DownloadAppContainer.Height := 241;
    DownloadAppContainer.HTML.BeginUpdate;
    try
      DownloadAppContainer.HTML.Clear;
      DownloadAppContainer.HTML.Add('<font size="4"><u>Android app voor de Youless energiemeter:</u></font><br>');
      DownloadAppContainer.HTML.Add('<font size="2">Met onze <FONT color="#FF0000">gratis</FONT> (Android) app krijgt u inzicht in uw energieverbruik (en de productie<br>');
      DownloadAppContainer.HTML.Add('van uw zonnepanelen). Daarvoor heeft u de Youless energiemeter nodig. Onze app<br>');
      DownloadAppContainer.HTML.Add('communiceert met de Youless en leest de geheugen data uit. De diverse instellingen<br>');
      DownloadAppContainer.HTML.Add('van de app maken overzichtelijke beelden in de vorm van grafieken.<br><br>');
      DownloadAppContainer.HTML.Add('De Youless energiemeter besteld u bij youless.nl en met de bijgeleverde handleiding<br>');
      DownloadAppContainer.HTML.Add('heeft u de Youless snel ge'#239'nstalleerd in de meterkast. Als in de router port-forwarding<br>');
      DownloadAppContainer.HTML.Add('wordt ingesteld kan de app ook via internet communiceren met de Youless.<br><br></font>');
      DownloadAppContainer.HTML.Add('');
    finally
      DownloadAppContainer.HTML.EndUpdate;
    end;
    DownloadProgContainer.SetParentComponent(DownloadPaneel);
    DownloadProgContainer.Name := 'DownloadProgContainer';
    DownloadProgContainer.Left := 56;
    DownloadProgContainer.Top := 282;
    DownloadProgContainer.Width := 580;
    DownloadProgContainer.Height := 429;
    DownloadProgContainer.ChildOrder := 1;
    DownloadProgContainer.HTML.BeginUpdate;
    try
      DownloadProgContainer.HTML.Clear;
      DownloadProgContainer.HTML.Add('<font size="4"><u>Windows programma voor de Youless energiemeter:</u></font><br>');
      DownloadProgContainer.HTML.Add('<font size="2">Met onze <FONT color="#FF0000">gratis</FONT><FONT size="2"> app '#39'YouLessSee'#39' krijgt u inzicht in uw energieverbruik (en de productie<br>');
      DownloadProgContainer.HTML.Add('van uw zonnepanelen). Het scherm van een smartphone is echter te klein om<br>');
      DownloadProgContainer.HTML.Add('uitgebreide informatie te tonen. Daarom hebben we ook een Windows applicatie<br>');
      DownloadProgContainer.HTML.Add('(programma) gemaakt. Deze is <font color="#FF0000">niet </font>gratis. We vragen hiervoor een kleine bijdrage<br>');
      DownloadProgContainer.HTML.Add('in de ontwikkelkosten. Gezien de extra mogelijkheden vragen we hiervoor '#8364' 10.<br><br>');
      DownloadProgContainer.HTML.Add('U kunt de Windows applicatie bij ons bestellen via een e-mail (zie Contact).<br>');
      DownloadProgContainer.HTML.Add('We hebben dan wat gegevens van u nodig, zoals uw naam, adres, woonplaats<br>');
      DownloadProgContainer.HTML.Add('en het MAC-adres van uw Youless energiemeter.<br>');
      DownloadProgContainer.HTML.Add('Zodra u heeft betaald krijgt u van ons een e-mail dat uw persoonlijke applicatie<br>');
      DownloadProgContainer.HTML.Add('gereed staat, waarna u deze van de website kunt downloaden. U krijgt van ons een<br>');
      DownloadProgContainer.HTML.Add(#39'key'#39' waarmee u toegang krijgt tot uw persoonlijke download.<br><br>');
      DownloadProgContainer.HTML.Add('Aan het programma wordt regelmatig functionaliteit toegevoegd. Als u gebruiker wordt kan u meedenken over welke functionaliteit er nodig / gewenst is. Zo kunt u mee ');
      DownloadProgContainer.HTML.Add('ontwikkelen waardoor het programma steeds beter en waardevoller wordt. Uiteraard kunt u de updates altijd gratis downloaden.<br></FONT> ');
      DownloadProgContainer.HTML.Add('');
    finally
      DownloadProgContainer.HTML.EndUpdate;
    end;
    Inloggen.SetParentComponent(DownloadPaneel);
    Inloggen.Name := 'Inloggen';
    Inloggen.Left := 664;
    Inloggen.Top := 375;
    Inloggen.Width := 70;
    Inloggen.Height := 20;
    Inloggen.Caption := 'Inloggen';
    Inloggen.ChildOrder := 8;
    Inloggen.Font.Charset := DEFAULT_CHARSET;
    Inloggen.Font.Color := clDarkslateblue;
    Inloggen.Font.Height := -12;
    Inloggen.Font.Name := 'Tahoma';
    Inloggen.Font.Style := [fsBold];
    Inloggen.HeightPercent := 100.000000000000000000;
    Inloggen.ParentFont := False;
    Inloggen.WidthPercent := 100.000000000000000000;
    SetEvent(Inloggen, Self, 'OnClick', 'InloggenClick');
    Inlogcode.SetParentComponent(DownloadPaneel);
    Inlogcode.Name := 'Inlogcode';
    Inlogcode.Left := 664;
    Inlogcode.Top := 400;
    Inlogcode.Width := 249;
    Inlogcode.Height := 22;
    Inlogcode.ChildOrder := 9;
    Inlogcode.Font.Charset := DEFAULT_CHARSET;
    Inlogcode.Font.Color := clDarkslateblue;
    Inlogcode.Font.Height := -12;
    Inlogcode.Font.Name := 'Tahoma';
    Inlogcode.Font.Style := [];
    Inlogcode.HeightPercent := 100.000000000000000000;
    Inlogcode.ParentFont := False;
    Inlogcode.Visible := False;
    Inlogcode.WidthPercent := 100.000000000000000000;
    Controle.SetParentComponent(DownloadPaneel);
    Controle.Name := 'Controle';
    Controle.Left := 843;
    Controle.Top := 428;
    Controle.Width := 70;
    Controle.Height := 20;
    Controle.Caption := 'Controle';
    Controle.ChildOrder := 8;
    Controle.Font.Charset := DEFAULT_CHARSET;
    Controle.Font.Color := clDarkslateblue;
    Controle.Font.Height := -12;
    Controle.Font.Name := 'Tahoma';
    Controle.Font.Style := [fsBold];
    Controle.HeightPercent := 100.000000000000000000;
    Controle.ParentFont := False;
    Controle.Visible := False;
    Controle.WidthPercent := 100.000000000000000000;
    SetEvent(Controle, Self, 'OnClick', 'ControleClick');
    Historie.SetParentComponent(HoofdMenu);
    Historie.Name := 'Historie';
    Historie.Left := 0;
    Historie.Top := 20;
    Historie.Width := 945;
    Historie.Height := 720;
    Historie.Caption := 'Historie';
    Historie.ChildOrder := 6;
    HistoriePaneel.SetParentComponent(Historie);
    HistoriePaneel.Name := 'HistoriePaneel';
    HistoriePaneel.Left := 0;
    HistoriePaneel.Top := 0;
    HistoriePaneel.Width := 945;
    HistoriePaneel.Height := 720;
    HistoriePaneel.ElementClassName := 'panels';
    HistoriePaneel.Align := alClient;
    HistoriePaneel.ChildOrder := 2;
    HistoriePaneel.Color := clWhite;
    WebLabel17.SetParentComponent(HistoriePaneel);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 56;
    WebLabel17.Top := 31;
    WebLabel17.Width := 99;
    WebLabel17.Height := 19;
    WebLabel17.Caption := 'Android app';
    WebLabel17.Font.Charset := DEFAULT_CHARSET;
    WebLabel17.Font.Color := clDarkslateblue;
    WebLabel17.Font.Height := -16;
    WebLabel17.Font.Name := 'Tahoma';
    WebLabel17.Font.Style := [fsBold,fsUnderline];
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.ParentFont := False;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(HistoriePaneel);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 476;
    WebLabel18.Top := 31;
    WebLabel18.Width := 158;
    WebLabel18.Height := 19;
    WebLabel18.Caption := 'Windows applicatie';
    WebLabel18.Font.Charset := DEFAULT_CHARSET;
    WebLabel18.Font.Color := clDarkslateblue;
    WebLabel18.Font.Height := -16;
    WebLabel18.Font.Name := 'Tahoma';
    WebLabel18.Font.Style := [fsBold,fsUnderline];
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.ParentFont := False;
    WebLabel18.WidthPercent := 100.000000000000000000;
    ContainerApp.SetParentComponent(HistoriePaneel);
    ContainerApp.Name := 'ContainerApp';
    ContainerApp.Left := 55;
    ContainerApp.Top := 57;
    ContainerApp.Width := 415;
    ContainerApp.Height := 639;
    ContainerApp.HTML.BeginUpdate;
    try
      ContainerApp.HTML.Clear;
      ContainerApp.HTML.Add('<I></I>');
      ContainerApp.HTML.Add('<FONT size="2"  face="Tahoma"><br>');
      ContainerApp.HTML.Add('Versie 2.00<br>');
      ContainerApp.HTML.Add('Deze nieuwe versie is grondig herzien (32 > 64 bits) en uitgebreid met nieuwe opties. In het tabblad '#39#39'Temperatuur KNMI'#39#39' kan ingesteld ');
      ContainerApp.HTML.Add('worden in welke grafiek men de temperatuur wil zien van de gekozen dag. De data voor deze grafiek komt van het KNMI. De KNMI heeft ');
      ContainerApp.HTML.Add('verspreidt over Nederland een flink aantal Weerstations, maar niet alle stations geven de temperatuur door. In de lijst met Weerstations kiest ');
      ContainerApp.HTML.Add('u het dichtsbijzijnde station. Van belang is om te weten dat het KNMI altijd een paar dagen achterloopt met het bijwerken van de data. ');
      ContainerApp.HTML.Add('Het kan dus voor komen dat u de laatste 1-3 dagen mist. Heeft men gekozen voor het tonen van de grafiek dan wordt bij de gekozen dag ');
      ContainerApp.HTML.Add('de temperatuur getoond van 9 to 18 uur. Tevens wordt van die dag de gemiddelde temperatuur vermeld. Uiteraard is dit het gemiddelde ');
      ContainerApp.HTML.Add('van 9 uur tot 18 uur. Kiest men voor de Maand weergave dan wordt bij een volle (hele) maand ook het gemiddelde van die hele maand ');
      ContainerApp.HTML.Add('vermeld. Zo kunt u een redelijk beeld krijgen of een maand warmer of kouder was dan een vorige maand en wat dit zegt over meer of ');
      ContainerApp.HTML.Add('minder verbruik van elektra en gas. Omdat de Youless maar van 70 dagen de uur-data en van 365 dagen de dag-data bewaard kan nooit');
      ContainerApp.HTML.Add('de temperatuur van 1 jaar terug in beeld gebracht worden. Daarom is de KNMI data beperkt tot maximaal 365 dagen.<br><br>');
      ContainerApp.HTML.Add('Versie 1.80<br>');
      ContainerApp.HTML.Add('Enkele kleine tekstuele aanpassingen gedaan. Tevens is in deze versie de (noodzakelijke) registratie aangepast voor een actuele melding over de ');
      ContainerApp.HTML.Add('beschikbaarheid van ');
      ContainerApp.HTML.Add('een nieuwe versie.<br><br>');
      ContainerApp.HTML.Add('Versie 1.70<br>');
      ContainerApp.HTML.Add('In alledrie de grafieken werd de tijd een uur te laat aangegeven. Dit is nu aangepast. Verder nog een paar kleine schoonheidsfoutjes eruit ');
      ContainerApp.HTML.Add('gehaald.<br><br>');
      ContainerApp.HTML.Add('Versie 1.60<br>');
      ContainerApp.HTML.Add('Het wachtwoord op de Youless mag niet zomaar alle tekens bevatten. In de app is nu een controle ingebouwd op verboden / toegestane tekens voor het ');
      ContainerApp.HTML.Add('wachtwoord. ');
      ContainerApp.HTML.Add('Wordt een verboden teken ingevoerd dan wordt dit gemeld en geweigerd.<br><br> ');
      ContainerApp.HTML.Add('Versie 1.55<br>');
      ContainerApp.HTML.Add('Bij elke energiesoort is nu (bij het instellen van de maand) ook het totaal van het huidige / lopende jaar te zien. Dit vergt wat meer rekenkracht en is dan ');
      ContainerApp.HTML.Add('ook iets langzamer ');
      ContainerApp.HTML.Add('voordat de jaar gegevens getoond worden. De rekensnelheid hangt uiteraard ook af van de processor van de smartphone.<br><br>');
      ContainerApp.HTML.Add('Versie 1.52<br>');
      ContainerApp.HTML.Add('De klok / timer in de Youless synchroniseert met de internet tijd. Sommige providers blokkeren dat en de klok kan van slag raken. De datum / tijd van de ');
      ContainerApp.HTML.Add('Youless ');
      ContainerApp.HTML.Add('kan nu opgevraagd en getoond worden om dit in de gaten te houden.<br>');
      ContainerApp.HTML.Add('Verder is er een controle toegevoegd op de invoer van een IP-adres. In een blok kan nooit een waarde hoger dan 255 voor komen. Daarop wordt nu ');
      ContainerApp.HTML.Add('getest.<br>');
      ContainerApp.HTML.Add('Tenslotte: Een aantal kleine schoonheidsfoutjes zijn opgelost.<br><br>');
      ContainerApp.HTML.Add('Versie 1.50<br>');
      ContainerApp.HTML.Add('Na een update naar Android 11 werkte de app niet meer op diverse smartphones. In deze versie is dat opgelost. Tevens is er een checkbox gemaakt om te ');
      ContainerApp.HTML.Add('kijken ');
      ContainerApp.HTML.Add('of er een update van de app op de website staat.<br><br>');
      ContainerApp.HTML.Add('Versie 1.40<br>');
      ContainerApp.HTML.Add('Bij het terugkijken naar vorige maanden bleek dat de verkeerde maand getoond werd. Dit is nu opgelost.<br><br>');
      ContainerApp.HTML.Add('Versie 1.30<br>');
      ContainerApp.HTML.Add('Bij de overstap van 2020 naar 2021 werd er niet meer geupdated en waren de maanden van het oude jaar niet meer zichtbaar. Ook de melding dat er meer ');
      ContainerApp.HTML.Add('dan 7 / 70 ');
      ContainerApp.HTML.Add('dagen gekozen werd bleek op verkeerde momenten getoond te worden. De lijst met maanden nu aangepast en de overige fouten opgelost.<br><br>');
      ContainerApp.HTML.Add('</FONT>');
    finally
      ContainerApp.HTML.EndUpdate;
    end;
    ContainerApp.ScrollStyle := ssVertical;
    ContainerWindows.SetParentComponent(HistoriePaneel);
    ContainerWindows.Name := 'ContainerWindows';
    ContainerWindows.Left := 476;
    ContainerWindows.Top := 57;
    ContainerWindows.Width := 415;
    ContainerWindows.Height := 639;
    ContainerWindows.ChildOrder := 1;
    ContainerWindows.HTML.BeginUpdate;
    try
      ContainerWindows.HTML.Clear;
      ContainerWindows.HTML.Add('<I></I> ');
      ContainerWindows.HTML.Add('<FONT size="2"  face="Tahoma"><br>');
      ContainerWindows.HTML.Add('Versie 2.40<br>');
      ContainerWindows.HTML.Add('Bij een firmware versie boven 1.5 voor de Youless trad er een probleem op bij het opstarten van het programma als men de Youless van een wachtwoord heeft ');
      ContainerWindows.HTML.Add('voorzien. Er verscheen een melding dat er een Javascript uitgevoerd wordt en dat leidde tot een error. Dit probleem is in versie 2.40 opgelost. De Youless kan dus ');
      ContainerWindows.HTML.Add('nu weer met een wachtwoord beveiligd worden.<br>');
      ContainerWindows.HTML.Add('Een andere belangrijke verbetering is het volgende. Gebruikers die in een persoonlijk (afgeschermd) netwerk zitten (bijvoorbeeld 10.0. etc) kunnen een crash krijgen. ');
      ContainerWindows.HTML.Add('Dit kan ontstaan als er een bepaalde poort dicht staat. Dit probleem is nu opgelost.<br><br>');
      ContainerWindows.HTML.Add('Versie 2.35<br>');
      ContainerWindows.HTML.Add('Er zijn een paar kleine tekstuele aanpassingen gedaan. De tijdsweergave bij Elektra en Zon bij de Moment-weergave staat nu wat beter uitgelijnd. En het belangrijkste ');
      ContainerWindows.HTML.Add('is ');
      ContainerWindows.HTML.Add('dat er een (noodzakelijke) verbetering in de Registratie is aangebracht. Voor een goede werking van het programma moet u een nieuwe persoonlijke versie aanvragen ');
      ContainerWindows.HTML.Add('via ');
      ContainerWindows.HTML.Add('de mail. Voor gebruikers is dit gratis.<br><br>');
      ContainerWindows.HTML.Add('Versie 2.32<br>');
      ContainerWindows.HTML.Add('Bij het updaten van de Maandoverzichten bleek de voortgangsbalk verdwenen te zijn. Deze is nu weer terug.<br><br>');
      ContainerWindows.HTML.Add('Versie 2.30<br>');
      ContainerWindows.HTML.Add('De nieuwste firmware van de Youless is aangepast naar een andere methode van data verzenden. Daarom werkt het programma niet meer goed samen met versie ');
      ContainerWindows.HTML.Add('1.4.5-');
      ContainerWindows.HTML.Add('PO. Het programma is daarop nu aangepast. Op dit moment werkt firmware 1.4.5-EL nog wel goed, maar op korte termijn zal ook deze firmare versie worden ');
      ContainerWindows.HTML.Add('aangepast door ');
      ContainerWindows.HTML.Add('de Youless leverancier. We raden daarom een overstap aan naar versie 2.30 van het programma. Bent u al gebruiker, dan kunt u de nieuwe versie aanvragen ');
      ContainerWindows.HTML.Add('(gratis).<br><br>');
      ContainerWindows.HTML.Add('Versie 2.20<br>');
      ContainerWindows.HTML.Add('* Het systeem van Registreren is opnieuw aangepast.<br> * Er zijn een aantal tekstuele aanpassingen aangebracht.<br>* De Update knoppen geven na het updaten ');
      ContainerWindows.HTML.Add('van ');
      ContainerWindows.HTML.Add('de data nu direct de juiste grafiek te zien. De soort update knop correspondeert nu dus met de grafiek.<br>* Bij de invoer van een wachtwoord wordt nu ');
      ContainerWindows.HTML.Add('gecontroleerd op ');
      ContainerWindows.HTML.Add('toegelaten / verboden karakters.<br>* Er is een tabblad bij gekomen met de naam '#39'Youless klok'#39', waarin de interface van de Youless opgevraagd kan worden. ');
      ContainerWindows.HTML.Add('Daardoor is ');
      ContainerWindows.HTML.Add('het mogelijk om aanpassingen uit te voeren als de klok niet goed synchroniseert met de internet tijd.<br>* Op het tabblad '#39'Vergelijken'#39' is nu een knop toegevoegd ');
      ContainerWindows.HTML.Add('waarmee ');
      ContainerWindows.HTML.Add('de grafiek kan worden opgeslagen, dit was eerder niet mogelijk.<br>* Het is nu mogelijk om de Legenda bij de grafieken uit te zetten als de waarden onder het ');
      ContainerWindows.HTML.Add('Legenda ');
      ContainerWindows.HTML.Add('veld niet meer zichtbaar zijn.<br>* In alle grafieken is nu de Legenda verbeterd zodat de belangrijkste data (totalen) nu ook zichtbaar zijn en tevens op de Kaart staan ');
      ContainerWindows.HTML.Add('als ');
      ContainerWindows.HTML.Add('deze ');
      ContainerWindows.HTML.Add('opgeslagen wordt.<br><br>');
      ContainerWindows.HTML.Add('Versie 2.10<br>');
      ContainerWindows.HTML.Add('Het registratiessysteem maakt nu gebruik van een LicentieKey. Deze key wordt gratis verstrekt en per mail naar een gebruiker verzonden. Een gebruiker kan een ');
      ContainerWindows.HTML.Add('nieuwe ');
      ContainerWindows.HTML.Add('key aanvragen als het programma niet meer werkt.<br><br>');
      ContainerWindows.HTML.Add('Versie 2.02<br>');
      ContainerWindows.HTML.Add('Migratie naar een ander registratiesysteem leidde tot een crash van het programma. In de nieuwe versie is dat opgelost. Gebruikers kunnen per mail een update ');
      ContainerWindows.HTML.Add('aanvragen.<br><br>');
      ContainerWindows.HTML.Add('Versie 2.00<br>');
      ContainerWindows.HTML.Add('Nieuw tabblad toegevoegd waarop een vergelijking mogelijk is tussen de verschillende jaren en maanden, waarbij gekozen kan worden uit verschillende ');
      ContainerWindows.HTML.Add('grafieksoorten. Een verbetering aangebracht om de actuele tijd van de Youless klok op te vragen. De keuze met welk tabblad het programma opgestart wordt ');
      ContainerWindows.HTML.Add('uitgebreid. Verder een aantal kleine verbeteringen aangebracht.<br><br>');
      ContainerWindows.HTML.Add('Versie 1.99<br>');
      ContainerWindows.HTML.Add('De klok / timer in de Youless synchroniseert met de internet tijd. Sommige providers blokkeren dat en de klok kan van slag raken. De datum / tijd van de Youless ');
      ContainerWindows.HTML.Add('kan nu opgevraagd en getoond worden om dit in de gaten te houden.<br>');
      ContainerWindows.HTML.Add('Verder is er een controle toegevoegd op de invoer van een IP-adres. In een blok kan nooit een waarde hoger dan 255 voor komen. Daarop wordt nu getest.<br>');
      ContainerWindows.HTML.Add('Tevens is er een checkbox gemaakt om te kijken / melden of er een update van het programma beschikbaar is.<br>');
      ContainerWindows.HTML.Add('Tenslotte: Een aantal kleine schoonheidsfoutjes zijn opgelost.<br><br>Versie 1.98<br>');
      ContainerWindows.HTML.Add('Op verzoek een onderdeel toegevoegd om de meterstanden in beeld te brengen, die bij Enelogic verzameld worden. Na een export vanuit Enelogic (csv bestand) en ');
      ContainerWindows.HTML.Add('het  ');
      ContainerWindows.HTML.Add('importeren in het programma wordt uit de diverse meterstanden het verbruik en de productie (zonnestroom) berekend en opgeslagen door het programma. Er kan nu ');
      ContainerWindows.HTML.Add('een ');
      ContainerWindows.HTML.Add('overzicht getoond worden per jaar, per maand en per dag. Dit geeft meer inzicht in Hoog en Laag tarief en een rendement voor de ');
      ContainerWindows.HTML.Add(#39'self supporting'#39'. Met name ');
      ContainerWindows.HTML.Add('belangrijk ');
      ContainerWindows.HTML.Add('voor onze Zuiderburen waar de salderingsregeling afgeschaft is.<br><br>');
      ContainerWindows.HTML.Add('Versie 1.92<br>');
      ContainerWindows.HTML.Add('Als het IP-adres bestaat uit 21 karakters (123.123.123.123:12345 - IP-adres + poort) ging het opslaan fout en werd er geen verbinding opgebouwd. Er werden maar ');
      ContainerWindows.HTML.Add('20 ');
      ContainerWindows.HTML.Add('karakters opgeslagen, dat zijn er nu 25.<br><br>');
      ContainerWindows.HTML.Add('Versie 1.91<br>');
      ContainerWindows.HTML.Add('De CheckBox Wachtwoord Tonen zichtbaar gemaakt in de Interface. Wachtwoord kan nu getoond worden. De datum van het laatste bezoek werkte niet meer. Is nu ');
      ContainerWindows.HTML.Add('weer ');
      ContainerWindows.HTML.Add('actueel. Als er geen verbinding is met de Youless staat er geen datum, anders wel.<br><br>');
      ContainerWindows.HTML.Add('Versie 1.90<br>');
      ContainerWindows.HTML.Add('Diverse problemen met de overstap van 2020 naar 2021 opgelost. Reparatieknop gaf fouten na de overstap naar een nieuw jaar. Alles opgelost.<br><br>');
      ContainerWindows.HTML.Add('</FONT>');
    finally
      ContainerWindows.HTML.EndUpdate;
    end;
    ContainerWindows.ScrollStyle := ssVertical;
    Screenshots.SetParentComponent(HoofdMenu);
    Screenshots.Name := 'Screenshots';
    Screenshots.Left := 0;
    Screenshots.Top := 20;
    Screenshots.Width := 945;
    Screenshots.Height := 720;
    Screenshots.Caption := 'Screenshots';
    Screenshots.ChildOrder := 7;
    ScreenshotPaneel.SetParentComponent(Screenshots);
    ScreenshotPaneel.Name := 'ScreenshotPaneel';
    ScreenshotPaneel.Left := 0;
    ScreenshotPaneel.Top := 0;
    ScreenshotPaneel.Width := 945;
    ScreenshotPaneel.Height := 720;
    ScreenshotPaneel.ElementClassName := 'panels';
    ScreenshotPaneel.Align := alClient;
    ScreenshotPaneel.Color := clWhite;
    SoftwareLBL.SetParentComponent(ScreenshotPaneel);
    SoftwareLBL.Name := 'SoftwareLBL';
    SoftwareLBL.Left := 56;
    SoftwareLBL.Top := 15;
    SoftwareLBL.Width := 69;
    SoftwareLBL.Height := 18;
    SoftwareLBL.Caption := 'Software';
    SoftwareLBL.Font.Charset := DEFAULT_CHARSET;
    SoftwareLBL.Font.Color := clDarkslateblue;
    SoftwareLBL.Font.Height := -15;
    SoftwareLBL.Font.Name := 'Tahoma';
    SoftwareLBL.Font.Style := [fsBold];
    SoftwareLBL.HeightPercent := 100.000000000000000000;
    SoftwareLBL.ParentFont := False;
    SoftwareLBL.WidthPercent := 100.000000000000000000;
    ImageSlider.SetParentComponent(ScreenshotPaneel);
    ImageSlider.Name := 'ImageSlider';
    ImageSlider.Left := 248;
    ImageSlider.Top := 20;
    ImageSlider.Width := 682;
    ImageSlider.Height := 448;
    ImageSlider.Appearance.NavigationStyle := navsNone;
    ImageSlider.Appearance.TransitionEffect := tefFlip;
    ImageSlider.Appearance.Bullets.Size := 18;
    ImageSlider.Appearance.Bullets.Opacity := 1.000000000000000000;
    ImageSlider.Appearance.Thumbnails.Opacity := 0.500000000000000000;
    ImageSlider.Appearance.Thumbnails.OpacityActive := 1.000000000000000000;
    ImageSlider.Appearance.Buttons.Color := clMedGray;
    ImageSlider.Color := clWhite;
    SoftwareKiezer.SetParentComponent(ScreenshotPaneel);
    SoftwareKiezer.Name := 'SoftwareKiezer';
    SoftwareKiezer.Left := 56;
    SoftwareKiezer.Top := 39;
    SoftwareKiezer.Width := 173;
    SoftwareKiezer.Height := 26;
    SoftwareKiezer.Font.Charset := DEFAULT_CHARSET;
    SoftwareKiezer.Font.Color := clDarkslateblue;
    SoftwareKiezer.Font.Height := -15;
    SoftwareKiezer.Font.Name := 'Tahoma';
    SoftwareKiezer.Font.Style := [];
    SoftwareKiezer.HeightPercent := 100.000000000000000000;
    SoftwareKiezer.ParentFont := False;
    SoftwareKiezer.WidthPercent := 100.000000000000000000;
    SetEvent(SoftwareKiezer, Self, 'OnChange', 'SoftwareKiezerChange');
    SoftwareKiezer.ItemIndex := -1;
    LaadFotos.SetParentComponent(ScreenshotPaneel);
    LaadFotos.Name := 'LaadFotos';
    LaadFotos.Left := 56;
    LaadFotos.Top := 82;
    LaadFotos.Width := 173;
    LaadFotos.Height := 25;
    LaadFotos.Caption := 'Laad foto'#39's';
    LaadFotos.ChildOrder := 1;
    LaadFotos.Font.Charset := DEFAULT_CHARSET;
    LaadFotos.Font.Color := clNavy;
    LaadFotos.Font.Height := -15;
    LaadFotos.Font.Name := 'Tahoma';
    LaadFotos.Font.Style := [fsBold];
    LaadFotos.HeightPercent := 100.000000000000000000;
    LaadFotos.ParentFont := False;
    LaadFotos.Visible := False;
    LaadFotos.WidthPercent := 100.000000000000000000;
    SetEvent(LaadFotos, Self, 'OnClick', 'LaadFotosClick');
    WebTimer.SetParentComponent(Self);
    WebTimer.Name := 'WebTimer';
    WebTimer.Enabled := False;
    WebTimer.Left := 1032;
    WebTimer.Top := 632;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Delimiter := ';';
    WebClientConnection1.Left := 1000;
    WebClientConnection1.Top := 574;
  finally
    Basis.AfterLoadDFMValues;
    TopPaneel.AfterLoadDFMValues;
    Titel.AfterLoadDFMValues;
    Zeelandbrug.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    BodemPaneel.AfterLoadDFMValues;
    CopyRight.AfterLoadDFMValues;
    WebMemo.AfterLoadDFMValues;
    RechterPaneel.AfterLoadDFMValues;
    LaatstBijgewerkt.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    Testen.AfterLoadDFMValues;
    HoofdPaneel.AfterLoadDFMValues;
    HoofdMenu.AfterLoadDFMValues;
    Algemeen.AfterLoadDFMValues;
    AlgemeenPanel.AfterLoadDFMValues;
    AlgemeenContainer.AfterLoadDFMValues;
    Diensten.AfterLoadDFMValues;
    DienstenPanel.AfterLoadDFMValues;
    DienstenContainer.AfterLoadDFMValues;
    Doelgroep.AfterLoadDFMValues;
    DoelgroepPanel.AfterLoadDFMValues;
    DoelgroepContainer.AfterLoadDFMValues;
    Werkwijze.AfterLoadDFMValues;
    WerkwijzePanel.AfterLoadDFMValues;
    WerkwijzeContainer.AfterLoadDFMValues;
    Contact.AfterLoadDFMValues;
    ContactPaneel.AfterLoadDFMValues;
    Plattegrond.AfterLoadDFMValues;
    ContactContainer.AfterLoadDFMValues;
    Download.AfterLoadDFMValues;
    DownloadPaneel.AfterLoadDFMValues;
    BezoekYouless.AfterLoadDFMValues;
    ScreenshotApp.AfterLoadDFMValues;
    DownloadApp.AfterLoadDFMValues;
    ScreenshotProg.AfterLoadDFMValues;
    DownloadLink.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    Test.AfterLoadDFMValues;
    WebLinkLabel1.AfterLoadDFMValues;
    DownloadAppContainer.AfterLoadDFMValues;
    DownloadProgContainer.AfterLoadDFMValues;
    Inloggen.AfterLoadDFMValues;
    Inlogcode.AfterLoadDFMValues;
    Controle.AfterLoadDFMValues;
    Historie.AfterLoadDFMValues;
    HistoriePaneel.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    ContainerApp.AfterLoadDFMValues;
    ContainerWindows.AfterLoadDFMValues;
    Screenshots.AfterLoadDFMValues;
    ScreenshotPaneel.AfterLoadDFMValues;
    SoftwareLBL.AfterLoadDFMValues;
    ImageSlider.AfterLoadDFMValues;
    SoftwareKiezer.AfterLoadDFMValues;
    LaadFotos.AfterLoadDFMValues;
    WebTimer.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
  end;
end;

end.
