{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.GoogleChart;

{$DEFINE NOPP}

interface

uses
  Classes, Web, JS, WEBLib.Controls, WEBLib.Graphics;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 1; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  // version history
  // 1.0.0.0 : First release
  // 1.0.0.1 : Fixed: Issue with OnLoaded and OnSelect events in Release mode
  // 1.0.1.0 : New: Support for multiple series with gctScatter type chart
  // 1.1.0.0 : New: Support for Annotations
  //         : New: ChartArea options

type
  TGoogleChartType = (gctArea, gctBar, gctBubble, gctBubbleColor, gctCandlestick,
    gctColumn, {*gctGauge, gctGeo,*} gctLine, gctPie, gctScatter, gctTimeLine);
  TGoogleChartEasing = (gceLinear, gceIn, gceOut, gceInAndOut);
  TGoogleChartStacked = (gcsDisabled, gcsAbsolute, gcsPercent, gcsRelative);
  TGoogleChartPosition = (gcpBottom, gcpLeft, gcpIn, gcpNone, gcpRight, gcpTop);
  TGoogleChartAlignment = (gcaAuto, gcaStart, gcaCenter, gcaEnd);
  TGoogleChartTooltip = (gcttHover, gcttNone, gcttSelect);
  TGoogleChartPieText = (gcptPercentage, gcptValue, gcptLabel, gcptNone);
  TGoogleChartPointShape = (gcpsCircle, gcpsTriangle, gcpsSquare, gcpsDiamond,
    gcpsStar, gcpsPolygon);
  TGoogleChartCurveType = (gcctNone, gcctFunction);
  TGoogleChartAnnotationType = (gcatNone, gcatData, gcatText);

  TGoogleChart = class;
  TGoogleChartSeries = class;
  TGoogleChartSeriesItem = class;

  TGoogleChartDataPoint = record
    Title: string;
    X: Double;
    Y: Double;
    Minimum: Double;
    Maximum: Double;
    StartTime: TDateTime;
    EndTime: TDateTime;
    Series: string;
    Size: Double;
    Value: Double;
    Country: string;
    Offset: Double;
    Color: TColor;
  end;

  TGoogleChartAnimationRecord = record
    Duration: string;
    Easing: string;
    Startup: string;
  end;

  TGoogleChartAnnotations = record
    AlwaysOutside: string;
    BoxStyle: string;
    Style: string;
    TextStyle: string;
  end;

  TGoogleChartColor = record
    Stroke: string;
    StrokeWidth: string;
    Fill: string;
  end;

  TGoogleChartViewWindow = record
    Max: string;
    Min: string;
  end;

  TGoogleChartAxisRecord = record
    Baseline: string;
    BaselineColor: string;
    Direction: string;
    Format: string;
    GridLines: string;
    MinorGridLines: string;
    TextPosition: string;
    TextStyle: string;
    Ticks: string;
    Title: string;
    TitleTextStyle: string;
    SlantedText: string;
    SlantedTextAngle: string;
    MaxValue: string;
    MinValue: string;
    ViewWindowMode: string;
    ViewWindow: TGoogleChartViewWindow;
  end;

  TGoogleChartLegendRecord = record
    Position: string;
    Alignment: string;
    TextStyle: string;
  end;

  TGoogleChartChartAreaRecord = record
    Top: string;
    Left: string;
    Width: string;
    Height: string;
  end;

  TGoogleChartTooltipRecord = record
    IgnoreBounds: string;
    ShowColorCode: string;
    TextStyle: string;
    Trigger: string;
  end;

  TGoogleChartBubble = record
    Opacity: string;
    Stroke: string;
    TextStyle: string;
  end;

  TGoogleChartColorAxisLegend = record
    Position: string;
    TextStyle: string;
    NumberFormat: string;
  end;

  TGoogleChartColorAxis = record
    MinValue: string;
    MaxValue: string;
    Values: string;
    Colors: string;
    Legend: TGoogleChartColorAxisLegend;
  end;

  TGoogleChartSizeAxis = record
    MaxSize: string;
    MaxValue: string;
    MinSize: string;
    MinValue: string;
  end;

  TGoogleChartCandleStick = record
    HollowIsRising: string;
    FallingColor: TGoogleChartColor;
    RisingColor: TGoogleChartColor;
  end;

  TGoogleChartOptions = record
    //General (not Gauge / Pie)
    Animation: TGoogleChartAnimationRecord;
    Annotations: TGoogleChartAnnotations;
    AxisTitlesPosition: string;
    BackgroundColor: TGoogleChartColor;
    Colors: string;
    DataOpacity: string;
    EnableInteractivity: string;
    FocusTarget: string;
    FontSize: string;
    FontName: string;
    HAxis: TGoogleChartAxisRecord;
    IsStacked: string;
    Legend: TGoogleChartLegendRecord;
    Orientation: string;
    ReverseCategories: string;
    Series: string;
    Title: string;
    TitlePosition: string;
    TitleTextStyle: string;
    Tooltip: TGoogleChartTooltipRecord;
    VAxis: TGoogleChartAxisRecord;
    //Column / Bar / Area / CandleStick / Line
    VAxes: string;
    //Column / Bar / Scatter
    Trendlines: string;
    //Area / Bubble / CandleStick / Line
    SelectionMode: string;
    //Area / CandleStick / Line / Pie / Scatter
    AggregationTarget: string;
    //Area
    AreaOpacity: string;
    InterpolateNulls: string;
    //Bubble
    Bubble: TGoogleChartBubble;
    ColorAxis: TGoogleChartColorAxis;
    SizeAxis: TGoogleChartSizeAxis;
    SortBubbleBySize: string;
    //CandleStick
    CandleStick: TGoogleChartCandleStick;
    //Gauge
    GreenColor: string;
    GreenFrom: string;
    GreenTo: string;
    MajorTicks: string;
    Max: string;
    Min: string;
    MinorTicks: string;
    RedColor: string;
    RedFrom: string;
    RedTo: string;
    YellowColor: string;
    YelloFrom: string;
    YellowTo: string;
    //Line
    CurveType: string;
    //Pie
    Is3D: string;
    PieHole: string;
    PieSliceBorderColor: string;
    PieSliceText: string;
    PieSliceTextStyle: string;
    PieStartAngle: string;
    PieResidueSliceColor: string;
    PieResidueSliceLabel: string;
    Slices: string;
    SliceVisibilityTreshold: string;
    ChartArea: TGoogleChartChartAreaRecord;
  end;

  TGoogleChartSelectEventArgs = class(TPersistent)
  private
    FPointIndex: Integer;
    FSeriesIndex: Integer;
  published
    property PointIndex: Integer read FPointIndex write FPointIndex;
    property SeriesIndex: Integer read FSeriesIndex write FSeriesIndex;
  end;

  TGoogleChartSelectEvent = procedure(Sender: TObject; Event: TGoogleChartSelectEventArgs) of object;
  TGoogleChartCustomizeChartEvent = procedure(Sender: TObject; var Options: TGoogleChartOptions) of object;
  TGoogleChartCustomizeChartJSON = procedure(Sender: TObject; var Options: string) of object;

  TGoogleChartSeriesValueItem = class(TCollectionItem)
  private
    FDataPoint: TGoogleChartDataPoint;
  published
    property DataPoint: TGoogleChartDataPoint read FDataPoint write FDataPoint;
  end;

  TGoogleChartSeriesValues = class(TCollection)
  private
    FOwner: TGoogleChartSeries;
    function GetItems(Index: integer): TGoogleChartSeriesValueItem;
    procedure SetItems(Index: integer; const Value: TGoogleChartSeriesValueItem);
  public
    constructor Create(AOwner: TGoogleChartSeries); reintroduce;
    procedure AddSinglePoint(AValue: Double; ALabel: string = '');
    procedure AddPiePoint(AValue: Double; ALabel: string = ''; Offset: Double = 0; Color: TColor = clNone);
    procedure AddXYPoint(X, Y: Double);
    procedure AddCandlestickPoint(X, Y, Minimum, Maximum: Double; ALabel: string = '');
    procedure AddTimelinePoint(StartTime, EndTime: TDateTime; ALabel: string = '');
    procedure AddBubblePoint(X, Y: Double; Series: string; Size: Double; ALabel: string = '');
    procedure AddBubbleColorPoint(X, Y: Double; Value: Double; ALabel: string = '');
//    procedure AddGeoPoint(Country: string; Value: Double);
    property Items[Index: integer]: TGoogleChartSeriesValueItem read GetItems write SetItems; default;
  end;

  TGoogleChartLineOptions = class(TPersistent)
  private
    FOwner: TGoogleChartSeriesItem;
    FPointSize: Integer;
    FPointShape: TGoogleChartPointShape;
    FLineWidth: Integer;
    procedure SetPointSize(const Value: Integer);
    procedure SetPointShape(const Value: TGoogleChartPointShape);
    procedure SetLineWidth(const Value: Integer);
  public
    constructor Create(AOwner: TGoogleChartSeriesItem); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property PointSize: Integer read FPointSize write SetPointSize default 7;
    property PointShape: TGoogleChartPointShape read FPointShape write SetPointShape default gcpsCircle;
    property LineWidth: Integer read FLineWidth write SetLineWidth default 2;
  end;

  TGoogleChartSeriesItem = class(TCollectionItem)
  private
    FOwner: TGoogleChartSeries;
    FChartType: TGoogleChartType;
    FTitle: string;
    FValues: TGoogleChartSeriesValues;
    FColor: TColor;
    FLine: TGoogleChartLineOptions;
    FTag: integer;
    FAnnotationText: string;
    FAnnotationType: TGoogleChartAnnotationType;
    procedure SetChartType(const Value: TGoogleChartType);
    procedure SetLine(const Value: TGoogleChartLineOptions);
    procedure SetColor(const Value: TColor);
  public
    constructor Create(Collection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property Values: TGoogleChartSeriesValues read FValues;
  published
    property Title: string read FTitle write FTitle;
    property AnnotationType: TGoogleChartAnnotationType read FAnnotationType write FAnnotationType default gcatNone;
    property AnnotationText: string read FAnnotationText write FAnnotationText;
    property ChartType: TGoogleChartType read FChartType write SetChartType default gctBar;
    property Color: TColor read FColor write SetColor default clNone;
    property Line: TGoogleChartLineOptions read FLine write SetLine;
    property Tag: integer read FTag write FTag default 0;
  end;

  TGoogleChartSeries = class(TOwnedCollection)
  private
    FOwner: TGoogleChart;
    function GetItems(Index: integer): TGoogleChartSeriesItem;
    procedure SetItems(Index: integer; const Value: TGoogleChartSeriesItem);
  public
    constructor Create(AOwner: TGoogleChart); overload;
    function GetOwner: TPersistent; override;
    function Add: TGoogleChartSeriesItem; reintroduce;
    function Insert(Index: Integer):TGoogleChartSeriesItem; reintroduce;
    property Items[Index: integer]: TGoogleChartSeriesItem read GetItems write SetItems; default;
  end;

  TGoogleChartAppearance = class;

  TGoogleChartAxis = class(TPersistent)
  private
    FOwner: TGoogleChartAppearance;
    FMinValue: Integer;
    FAutoMaxMinValue: Boolean;
    FMaxValue: Integer;
    FTextColor: TColor;
    procedure SetAutoMaxMinValue(const Value: Boolean);
    procedure SetMaxValue(const Value: Integer);
    procedure SetMinValue(const Value: Integer);
  public
    constructor Create(AOwner: TGoogleChartAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property MaxValue: Integer read FMaxValue write SetMaxValue default 0;
    property MinValue: Integer read FMinValue write SetMinValue default 0;
    property TextColor: TColor read FTextColor write FTextColor default clNone;
    property AutoMaxMinValue: Boolean read FAutoMaxMinValue write SetAutoMaxMinValue default True;
  end;

  TGoogleChartAnimation = class(TPersistent)
  private
    FOwner: TGoogleChartAppearance;
    FDuration: Integer;
    FEasing: TGoogleChartEasing;
    FStartup: Boolean;
    procedure SetDuration(const Value: Integer);
    procedure SetEasing(const Value: TGoogleChartEasing);
    procedure SetStartup(const Value: Boolean);
  public
    constructor Create(AOwner: TGoogleChartAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Duration: Integer read FDuration write SetDuration default 1000;
    property Easing: TGoogleChartEasing read FEasing write SetEasing default gceOut;
    property Startup: Boolean read FStartup write SetStartup default true;
  end;

  TGoogleChartBackground = class(TPersistent)
  private
    FOwner: TGoogleChartAppearance;
    FBorderColor: TColor;
    FColor: TColor;
    FBorderWidth: Integer;
    procedure SetBorderColor(const Value: TColor);
    procedure SetBorderWidth(const Value: Integer);
    procedure SetColor(const Value: TColor);
  public
    constructor Create(AOwner: TGoogleChartAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Color: TColor read FColor write SetColor default clNone;
    property BorderColor: TColor read FBorderColor write SetBorderColor default clNone;
    property BorderWidth: Integer read FBorderWidth write SetBorderWidth default 0;
  end;

  TGoogleChartLegend = class(TPersistent)
  private
    FOwner: TGoogleChartAppearance;
    FAlignment: TGoogleChartAlignment;
    FPosition: TGoogleChartPosition;
    procedure SetAlignment(const Value: TGoogleChartAlignment);
    procedure SetPosition(const Value: TGoogleChartPosition);
  public
    constructor Create(AOwner: TGoogleChartAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Position: TGoogleChartPosition read FPosition write SetPosition default gcpRight;
    property Alignment: TGoogleChartAlignment read FAlignment write SetAlignment default gcaAuto;
  end;

  TGoogleChartPie = class(TPersistent)
  private
    FOwner: TGoogleChartAppearance;
    FEnable3D: Boolean;
    FPieHole: Double;
    FPieSliceText: TGoogleChartPieText;
    procedure SetEnable3D(const Value: Boolean);
    procedure SetPieHole(const Value: Double);
    procedure SetPieSliceText(const Value: TGoogleChartPieText);
  public
    constructor Create(AOwner: TGoogleChartAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Enable3D: Boolean read FEnable3D write SetEnable3D default false;
    property PieHole: Double read FPieHole write SetPieHole;
    property PieSliceText: TGoogleChartPieText read FPieSliceText write SetPieSliceText;
  end;

  TGoogleChartLine = class(TPersistent)
  private
    FOwner: TGoogleChartAppearance;
    FCurveType: TGoogleChartCurveType;
    procedure SetCurveType(const Value: TGoogleChartCurveType);
  public
    constructor Create(AOwner: TGoogleChartAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property CurveType: TGoogleChartCurveType read FCurveType write SetCurveType default gcctNone;
  end;

   TGoogleChartAppearance = class(TPersistent)
  private
    FOwner: TGoogleChart;
    FStacked: TGoogleChartStacked;
    FAnimation: TGoogleChartAnimation;
    FBackground: TGoogleChartBackground;
    FLegend: TGoogleChartLegend;
    FReverseCategories: Boolean;
    FTooltip: TGoogleChartTooltip;
    FPieChart: TGoogleChartPie;
    FVAxis: TGoogleChartAxis;
    FHAxis: TGoogleChartAxis;
    FLineChart: TGoogleChartLine;
    procedure SetStacked(const Value: TGoogleChartStacked);
    procedure SetAnimation(const Value: TGoogleChartAnimation);
    procedure SetBackground(const Value: TGoogleChartBackground);
    procedure SetLegend(const Value: TGoogleChartLegend);
    procedure SetReverseCategories(const Value: Boolean);
    procedure SetTooltip(const Value: TGoogleChartTooltip);
    procedure SetPieChart(const Value: TGoogleChartPie);
    procedure SetVAxis(const Value: TGoogleChartAxis);
    procedure SetHAxis(const Value: TGoogleChartAxis);
    procedure SetLineChart(const Value: TGoogleChartLine);
  public
    constructor Create(AOwner: TGoogleChart); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Animation: TGoogleChartAnimation read FAnimation write SetAnimation;
    property Background: TGoogleChartBackground read FBackground write SetBackground;
    property Legend: TGoogleChartLegend read FLegend write SetLegend;
    property ReverseCategories: Boolean read FReverseCategories write SetReverseCategories default False;
    property Stacked: TGoogleChartStacked read FStacked write SetStacked default gcsDisabled;
    property Tooltip: TGoogleChartTooltip read FTooltip write SetTooltip default gcttHover;
    property PieChart: TGoogleChartPie read FPieChart write SetPieChart;
    property LineChart: TGoogleChartLine read FLineChart write SetLineChart;
    property VAxis: TGoogleChartAxis read FVAxis write SetVAxis;
    property HAxis: TGoogleChartAxis read FHAxis write SetHAxis;
  end;

  TGoogleChart = class(TCustomControl)
  private
    FDesignTime: boolean;
    FChartData: TJSObject;
    FChartObject: TJSObject;
    FTitle: string;
    FSeries: TGoogleChartSeries;
    FOnLoad: TNotifyEvent;
    FOnSelect: TGoogleChartSelectEvent;
//    FMapsAPIKey: string;
    FOptions: TStringList;
    FAppearance: TGoogleChartAppearance;
    FOnCustomizeChart: TGoogleChartCustomizeChartEvent;
    FOnCustomizeChartJSON: TGoogleChartCustomizeChartJSON;
    procedure SetTitle(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure BuildChartData(Serie: TGoogleChartSeriesItem; DataPoint: TGoogleChartDataPoint; DataArray: TJSArray; AddTitle: Boolean);
    function BuildColor(PropertyName: string; Color: TGoogleChartColor; First: Boolean): string;
    function GetChartObject: TJSObject;
    function GetDataObject: TJSObject;
    function HandleLoaded(Event: TJSEvent): Boolean;
    function HandleSelect(PointIndex, SeriesIndex: Integer): Boolean;
    function GetID: string; override;
    procedure Loaded; override;
    procedure SetSampleData;
  public
    procedure CreateInitialize; override;
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); override;
    property Chart: TJSObject read GetChartObject;
    property Data: TJSObject read GetDataObject;
//    property MapsAPIKey: string read FMapsAPIKey write FMapsAPIKey;
    procedure SetOption(AOption, AValue: string); overload;
    procedure SetOption(AOption: string; AValue: Boolean); overload;
    procedure SetOption(AOption: string; AValue: TJSObject); overload;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Enabled;
    property ElementClassName;
    property ElementID;
    property ElementPosition;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Left;
    property Margins;
    property Options: TStringList read FOptions write FOptions;
    property Series: TGoogleChartSeries read FSeries write FSeries;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property Appearance: TGoogleChartAppearance read FAppearance write FAppearance;
    property Title: string read FTitle write SetTitle;

    property OnLoaded: TNotifyEvent read FOnLoad write FOnLoad;
    property OnSelect: TGoogleChartSelectEvent read FOnSelect write FOnSelect;
    property OnCustomizeChart: TGoogleChartCustomizeChartEvent read FOnCustomizeChart write FOnCustomizeChart;
    property OnCustomizeChartJSON:  TGoogleChartCustomizeChartJSON read FOnCustomizeChartJSON write FOnCustomizeChartJSON;
  end;

  TWebGoogleChart = class(TGoogleChart);

implementation

uses
  SysUtils, WebLib.WebTools;

{ TGoogleChart }

procedure TGoogleChart.BuildChartData(Serie: TGoogleChartSeriesItem;
  DataPoint: TGoogleChartDataPoint; DataArray: TJSArray; AddTitle: Boolean);
var
  pair: TJSArray;
begin
  pair := DataArray;

  case Serie.ChartType of
    gctArea,
    gctBar,
    gctColumn,
    gctLine:
    begin
      if AddTitle then
        pair.push(DataPoint.Title);
      pair.push(DataPoint.X);
    end;

    gctBubble:
    begin
      pair.push(DataPoint.Title);
      pair.push(DataPoint.X);
      pair.push(DataPoint.Y);
      pair.push(DataPoint.Series);
      pair.push(DataPoint.Size);
    end;

    gctBubbleColor:
    begin
      pair.push(DataPoint.Title);
      pair.push(DataPoint.X);
      pair.push(DataPoint.Y);
      pair.push(DataPoint.Value);
    end;

    gctCandlestick:
    begin
      pair.push(DataPoint.Title);
      pair.push(DataPoint.X);
      pair.push(DataPoint.Y);
      pair.push(DataPoint.Minimum);
      pair.push(DataPoint.Maximum);
    end;

//    gctGauge,
    gctPie:
    begin
      if AddTitle then
        pair.push(DataPoint.Title);
      pair.push(DataPoint.X);
    end;

//    gctGeo:
//    begin
//      pair.push(DataPoint.Country);
//      pair.push(DataPoint.Value);
//    end;

    gctScatter:
    begin
      if AddTitle then
        pair.push(DataPoint.X);
      pair.push(DataPoint.Y);
    end;

    gctTimeline:
    begin
      pair.push(DataPoint.Title);
      pair.push(TJSDate.Parse(DateTimeToStr(DataPoint.StartTime)));
      pair.push(TJSDate.Parse(DateTimeToStr(DataPoint.EndTime)));
    end;
  end;
end;

constructor TGoogleChart.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
end;

function TGoogleChart.CreateElement: TJSElement;
var
  LLabel: TJSHTMLElement;
begin
  if (csDesigning in ComponentState) and (Series.Count = 0) then
  begin
    Result := document.createElement('DIV');
    LLabel := TJSHTMLElement(document.createElement('DIV'));
    LLabel.innerHTML := 'TWebGoogleChart';
    BorderStyle := bsSingle;
    LLabel['align'] := 'center';
    LLabel.style.setProperty('border','1px solid gray');
    LLabel.style.setProperty('vertical-align','middle');
    LLabel.style.setProperty('display','table-cell');
    Result.appendChild(LLabel);
  end
  else
  begin
    Result := document.createElement('DIV');
  end;
end;

procedure TGoogleChart.CreateInitialize;
begin
  inherited;
  FAppearance := TGoogleChartAppearance.Create(Self);
  FTitle := '';
  FOptions := TStringList.Create;
  FSeries := TGoogleChartSeries.Create(Self);
  FSeries.PropName := 'Series';
  Width := 400;
  Height := 300;

  FDesignTime := (csDesigning in ComponentState) and not
    ((csReading in Owner.ComponentState) or (csLoading in Owner.ComponentState));

  // create initial sample series
  if FDesignTime then
  begin
    Appearance.Animation.Startup := false;
    FSeries.Clear;
    FSeries.Add;
    FSeries.Items[0].ChartType := gctLine;
    FSeries.Items[0].Color := clRed;
    FSeries.Items[0].Tag := -1;
    SetSampleData;
  end;
end;

destructor TGoogleChart.Destroy;
begin
  FAppearance.Free;
  FOptions.Free;
  FSeries.Free;
  inherited;
end;

function TGoogleChart.GetChartObject: TJSObject;
begin
  Result := FChartObject;
end;

function TGoogleChart.GetDataObject: TJSObject;
begin
  Result := FChartData;
end;

function TGoogleChart.GetID: string;
begin
  Result := inherited GetID;

  if Result = '' then
  begin
    ElementID := GetNewName;
    Result := ElementID;
  end;
end;

function TGoogleChart.HandleLoaded(Event: TJSEvent): Boolean;
begin
  if Assigned(OnLoaded) then
    OnLoaded(Self);
  Result := True;
end;

function TGoogleChart.HandleSelect(PointIndex, SeriesIndex: Integer): Boolean;
var
  Args: TGoogleChartSelectEventArgs;
begin
  if Assigned(OnSelect) then
  begin
    Args := TGoogleChartSelectEventArgs.Create;
    Args.PointIndex := PointIndex;
    Args.SeriesIndex := SeriesIndex - 1;

    //Some chart types cannot have more than 1 series
    //SeriesIndex must always be 0
    if Series.Count > 0 then
    begin
      if Series[0].ChartType in [gctPie, gctTimeLine, gctBubble, gctBubbleColor] then
        Args.SeriesIndex := 0;
    end;

    OnSelect(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TGoogleChart.Loaded;
begin
  inherited;
  // it is the one & only design-time series
  SetSampleData;
end;

procedure TGoogleChart.SetOption(AOption: string; AValue: Boolean);
begin
  SetOption(AOption, Lowercase(BoolToStr(AValue, True)));
end;

procedure TGoogleChart.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TGoogleChart.SetOption(AOption: string; AValue: TJSObject);
var
  wrapper: TJSObject;
begin
  wrapper := GetChartObject;

  if Assigned(wrapper) then
  begin
    asm
      wrapper.setOption(AOption, AValue);
      wrapper.draw();
    end;
  end;
end;

procedure TGoogleChart.SetSampleData;
var
  i: integer;
begin
  if (FSeries.Count = 1) and (FSeries.Items[0].ChartType = gctLine) and (FSeries.Items[0].Color = clRed) and (FSeries.Items[0].Tag = -1) then
  begin
    for i := 0 to 10 do
      FSeries.Items[0].Values.AddSinglePoint(random(100));
  end;
end;

procedure TGoogleChart.SetTitle(const Value: string);
begin
  if (FTitle <> Value) then
  begin
    FTitle := Value;
    UpdateElement;
  end;
end;

procedure TGoogleChart.SetOption(AOption, AValue: string);
var
  wrapper: TJSObject;
begin
  wrapper := GetChartObject;

  if Assigned(wrapper) then
  begin
    asm
      wrapper.setOption(AOption, AValue);
      wrapper.draw();
    end;
  end;
end;

{ TGoogleChartSeriesValues }

procedure TGoogleChartSeriesValues.AddBubbleColorPoint(X, Y, Value: Double;
  ALabel: string);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.X := X;
  sv.DataPoint.Y := Y;
  sv.DataPoint.Value := Value;
  sv.DataPoint.Title := ALabel;
  FOwner.FOwner.UpdateElement;
end;

procedure TGoogleChartSeriesValues.AddBubblePoint(X, Y: Double; Series: string;
  Size: Double; ALabel: string);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.X := X;
  sv.DataPoint.Y := Y;
  sv.DataPoint.Series := Series;
  sv.DataPoint.Size := Size;
  sv.DataPoint.Title := ALabel;
  FOwner.FOwner.UpdateElement;
end;

procedure TGoogleChartSeriesValues.AddCandlestickPoint(X, Y, Minimum, Maximum: Double; ALabel: string);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.X := X;
  sv.DataPoint.Y := Y;
  sv.DataPoint.Minimum := Minimum;
  sv.DataPoint.Maximum := Maximum;
  sv.DataPoint.Title := ALabel;
  FOwner.FOwner.UpdateElement;
end;

//procedure TGoogleChartSeriesValues.AddGeoPoint(Country: string; Value: Double);
//var
//  sv: TGoogleChartSeriesValueItem;
//begin
//  sv := Add as TGoogleChartSeriesValueItem;
//  sv.DataPoint.Country := Country;
//  sv.DataPoint.Value := Value;
//  FOwner.FOwner.UpdateElement;
//end;

procedure TGoogleChartSeriesValues.AddPiePoint(AValue: Double; ALabel: string;
  Offset: Double; Color: TColor);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.X := AValue;
  sv.DataPoint.Title := ALabel;
  sv.DataPoint.Offset := Offset;
  sv.DataPoint.Color := Color;
  FOwner.FOwner.UpdateElement;
end;

procedure TGoogleChartSeriesValues.AddSinglePoint(AValue: Double;
  ALabel: string);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.X := AValue;
  sv.DataPoint.Title := ALabel;
  FOwner.FOwner.UpdateElement;
end;

procedure TGoogleChartSeriesValues.AddTimelinePoint(StartTime,
  EndTime: TDateTime; ALabel: string);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.StartTime := StartTime;
  sv.DataPoint.EndTime := EndTime;
  sv.DataPoint.Title := ALabel;
  FOwner.FOwner.UpdateElement;
end;

procedure TGoogleChartSeriesValues.AddXYPoint(X, Y: Double);
var
  sv: TGoogleChartSeriesValueItem;
begin
  sv := Add as TGoogleChartSeriesValueItem;
  sv.DataPoint.X := X;
  sv.DataPoint.Y := Y;
  FOwner.FOwner.UpdateElement;
end;

constructor TGoogleChartSeriesValues.Create(AOwner: TGoogleChartSeries);
begin
  inherited Create(TGoogleChartSeriesValueItem);

  if AOwner is TGoogleChartSeries then
  begin
    FOwner := AOwner as TGoogleChartSeries;
  end;
end;

function TGoogleChartSeriesValues.GetItems(
  Index: integer): TGoogleChartSeriesValueItem;
begin
  Result := TGoogleChartSeriesValueItem(inherited Items[Index]);
end;

procedure TGoogleChartSeriesValues.SetItems(Index: integer;
  const Value: TGoogleChartSeriesValueItem);
begin
  inherited Items[Index] := Value;
end;

{ TGoogleChartSeries }

function TGoogleChartSeries.Add: TGoogleChartSeriesItem;
begin
  Result := TGoogleChartSeriesItem(inherited Add);
end;

constructor TGoogleChartSeries.Create(AOwner: TGoogleChart);
begin
  inherited Create(AOwner, TGoogleChartSeriesItem);
  FOwner := AOwner;
end;

function TGoogleChartSeries.GetItems(Index: integer): TGoogleChartSeriesItem;
begin
  Result := TGoogleChartSeriesItem(inherited Items[Index]);
end;

function TGoogleChartSeries.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TGoogleChartSeries.Insert(Index: Integer): TGoogleChartSeriesItem;
begin
  Result := TGoogleChartSeriesItem(inherited Insert(Index));
end;

procedure TGoogleChartSeries.SetItems(Index: integer;
  const Value: TGoogleChartSeriesItem);
begin
  inherited Items[Index] := Value;
end;


{ TGoogleChartSeriesItem }

procedure TGoogleChartSeriesItem.Assign(Source: TPersistent);
begin
  if (Source is TGoogleChartSeriesItem) then
  begin
    FTitle := (Source as TGoogleChartSeriesItem).Title;
    FChartType := (Source as TGoogleChartSeriesItem).ChartType;
    FColor := (Source as TGoogleChartSeriesItem).Color;
    FLine := (Source as TGoogleChartSeriesItem).Line;
    FTag := (Source as TGoogleChartSeriesItem).Tag;
  end;
end;

constructor TGoogleChartSeriesItem.Create(Collection: TCollection);
begin
  inherited;
  FChartType := gctBar;
  FTitle := '';
  FColor := clNone;

  if Collection is TGoogleChartSeries then
  begin
    FOwner := Collection as TGoogleChartSeries;
    FValues := TGoogleChartSeriesValues.Create(FOwner);
    FLine := TGoogleChartLineOptions.Create(Self);
  end;
end;

destructor TGoogleChartSeriesItem.Destroy;
begin
  FValues.Free;
  FLine.Free;
  inherited;
end;

procedure TGoogleChartSeriesItem.SetChartType(const Value: TGoogleChartType);
begin
  if FChartType <> Value then
  begin
    FChartType := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartSeriesItem.SetColor(const Value: TColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartSeriesItem.SetLine(const Value: TGoogleChartLineOptions);
begin
  FLine.Assign(Value);
end;

{ TGoogleChartAppearance }

procedure TGoogleChartAppearance.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartAppearance) then
  begin
    FStacked := (Source as TGoogleChartAppearance).Stacked;
    FAnimation.Assign((Source as TGoogleChartAppearance).Animation);
    FBackground.Assign((Source as TGoogleChartAppearance).Background);
    FLegend.Assign((Source as TGoogleChartAppearance).Legend);
    FReverseCategories := (Source as TGoogleChartAppearance).ReverseCategories;
    FTooltip := (Source as TGoogleChartAppearance).Tooltip;
    FPieChart.Assign((Source as TGoogleChartAppearance).PieChart);
    FVAxis.Assign((Source as TGoogleChartAxis));
    FHAxis.Assign((Source as TGoogleChartAxis));
    FLineChart.Assign((Source as TGoogleChartLine));
  end;
end;

constructor TGoogleChartAppearance.Create(AOwner: TGoogleChart);
begin
  inherited Create;
  FStacked := gcsDisabled;
  FAnimation := TGoogleChartAnimation.Create(Self);
  FBackground := TGoogleChartBackground.Create(Self);
  FLegend := TGoogleChartLegend.Create(Self);
  FReverseCategories := False;
  FToolTip := gcttHover;
  FPieChart := TGoogleChartPie.Create(Self);
  FVAxis := TGoogleChartAxis.Create(Self);
  FHAxis := TGoogleChartAxis.Create(Self);
  FLineChart := TGoogleChartLine.Create(Self);
  FOwner := AOwner;
end;

destructor TGoogleChartAppearance.Destroy;
begin
  FAnimation.Free;
  FBackground.Free;
  FLegend.Free;
  FPieChart.Free;
  FVAxis.Free;
  FHAxis.Free;
  FLineChart.Free;
  inherited;
end;

function TGoogleChartAppearance.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartAppearance.SetAnimation(const Value: TGoogleChartAnimation);
begin
  FAnimation.Assign(Value);
end;

procedure TGoogleChartAppearance.SetBackground(
  const Value: TGoogleChartBackground);
begin
  FBackground.Assign(Value);
end;

procedure TGoogleChartAppearance.SetHAxis(const Value: TGoogleChartAxis);
begin
  FHAxis.Assign(Value);
end;

procedure TGoogleChartAppearance.SetLegend(const Value: TGoogleChartLegend);
begin
  if Value <> FLegend then
  begin
    FLegend := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAppearance.SetLineChart(const Value: TGoogleChartLine);
begin
  FLineChart.Assign(Value);
end;

procedure TGoogleChartAppearance.SetPieChart(const Value: TGoogleChartPie);
begin
  FPieChart.Assign(Value);
end;

procedure TGoogleChartAppearance.SetReverseCategories(const Value: Boolean);
begin
  if Value <> FReverseCategories then
  begin
    FReverseCategories := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAppearance.SetStacked(const Value: TGoogleChartStacked);
begin
  if Value <> FStacked then
  begin
    FStacked := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAppearance.SetTooltip(const Value: TGoogleChartTooltip);
begin
  if Value <> FToolTip then
  begin
    FTooltip := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAppearance.SetVAxis(const Value: TGoogleChartAxis);
begin
  FVAxis.Assign(Value);
end;

{ TGoogleChartAnimation }

procedure TGoogleChartAnimation.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartAnimation) then
  begin
    FDuration := (Source as TGoogleChartAnimation).Duration;
  end;
end;

constructor TGoogleChartAnimation.Create(AOwner: TGoogleChartAppearance);
begin
  inherited Create;
  FDuration := 1000;
  FEasing := gceOut;
  FStartup := True;
  FOwner := AOwner;
end;

destructor TGoogleChartAnimation.Destroy;
begin
  inherited;
end;

function TGoogleChartAnimation.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartAnimation.SetDuration(const Value: Integer);
begin
  if FDuration <> Value then
  begin
    FDuration := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAnimation.SetEasing(const Value: TGoogleChartEasing);
begin
  if FEasing <> Value then
  begin
    FEasing := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAnimation.SetStartup(const Value: Boolean);
begin
  if FStartup <> Value then
  begin
    FStartup := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

{ TGoogleChartBackground }

procedure TGoogleChartBackground.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartBackground) then
  begin
    Color := (Source as TGoogleChartBackground).Color;
    BorderColor := (Source as TGoogleChartBackground).BorderColor;
    BorderWidth := (Source as TGoogleChartBackground).BorderWidth;
  end;
end;

constructor TGoogleChartBackground.Create(AOwner: TGoogleChartAppearance);
begin
  inherited Create;
  FColor := clNone;
  FBorderColor := clNone;
  FBorderWidth := 0;
  FOwner := AOwner;
end;

destructor TGoogleChartBackground.Destroy;
begin
  inherited;
end;

function TGoogleChartBackground.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartBackground.SetBorderColor(const Value: TColor);
begin
  if FBorderColor <> Value then
  begin
    FBorderColor := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartBackground.SetBorderWidth(const Value: Integer);
begin
  if FBorderWidth <> Value then
  begin
    FBorderWidth := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartBackground.SetColor(const Value: TColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

{ TGoogleChartLegend }

procedure TGoogleChartLegend.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartLegend) then
  begin
    FPosition := (Source as TGoogleChartLegend).Position;
    FAlignment := (Source as TGoogleChartLegend).Alignment;
  end;
end;

constructor TGoogleChartLegend.Create(AOwner: TGoogleChartAppearance);
begin
  inherited Create;
  FPosition := gcpRight;
  FAlignment := gcaAuto;
  FOwner := AOwner;
end;

destructor TGoogleChartLegend.Destroy;
begin
  inherited;
end;

function TGoogleChartLegend.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartLegend.SetAlignment(const Value: TGoogleChartAlignment);
begin
  if FAlignment <> Value then
  begin
    FAlignment := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartLegend.SetPosition(const Value: TGoogleChartPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

{ TGoogleChartPie }

procedure TGoogleChartPie.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartPie) then
  begin
    FEnable3D := (Source as TGoogleChartPie).Enable3D;
    FPieHole := (Source as TGoogleChartPie).PieHole;
  end;
end;

constructor TGoogleChartPie.Create(AOwner: TGoogleChartAppearance);
begin
  inherited Create;
  FEnable3D := False;
  FPieHole := 0.4;
  FOwner := AOwner;
end;

destructor TGoogleChartPie.Destroy;
begin
  inherited;
end;

function TGoogleChartPie.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartPie.SetEnable3D(const Value: Boolean);
begin
  if FEnable3D <> Value then
  begin
    FEnable3D := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartPie.SetPieHole(const Value: Double);
begin
  if FPieHole <> Value then
  begin
    FPieHole := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartPie.SetPieSliceText(const Value: TGoogleChartPieText);
begin
  if FPieSliceText <> Value then
  begin
    FPieSliceText := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

{ TGoogleChartLine }

procedure TGoogleChartLine.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartLine) then
  begin
    CurveType := (Source as TGoogleChartLine).FCurveType;
  end;
end;

constructor TGoogleChartLine.Create(AOwner: TGoogleChartAppearance);
begin
  FCurveType := gcctNone;
  FOwner := AOwner;
end;

destructor TGoogleChartLine.Destroy;
begin
  inherited;
end;

function TGoogleChartLine.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartLine.SetCurveType(const Value: TGoogleChartCurveType);
begin
  if FCurveType <> Value then
  begin
    FCurveType := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

{ TGoogleChartLineOptions }

procedure TGoogleChartLineOptions.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartLineOptions) then
  begin
    FPointSize := (Source as TGoogleChartLineOptions).PointSize;
    FPointShape := (Source as TGoogleChartLineOptions).PointShape;
    FLineWidth := (Source as TGoogleChartLineOptions).LineWidth;
  end;
end;

constructor TGoogleChartLineOptions.Create(AOwner: TGoogleChartSeriesItem);
begin
  inherited Create;
  FPointSize := 7;
  FPointShape := gcpsCircle;
  FLineWidth := 2;
  FOwner := AOwner;
end;

destructor TGoogleChartLineOptions.Destroy;
begin
  inherited;
end;

function TGoogleChartLineOptions.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartLineOptions.SetLineWidth(const Value: Integer);
begin
  if FLineWidth <> Value then
  begin
    FLineWidth := Value;
    FOwner.FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartLineOptions.SetPointShape(
  const Value: TGoogleChartPointShape);
begin
  if FPointShape <> Value then
  begin
    FPointShape := Value;
    FOwner.FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartLineOptions.SetPointSize(const Value: Integer);
begin
  if FPointSize <> Value then
  begin
    FPointSize := Value;
    FOwner.FOwner.FOwner.UpdateElement;
  end;
end;


{ TGoogleChartAxis }

procedure TGoogleChartAxis.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TGoogleChartAxis) then
  begin
    FMaxValue := (Source as TGoogleChartAxis).MaxValue;
    FMinValue := (Source as TGoogleChartAxis).MinValue;
    FAutoMaxMinValue := (Source as TGoogleChartAxis).AutoMaxMinValue;
  end;
end;

constructor TGoogleChartAxis.Create(AOwner: TGoogleChartAppearance);
begin
  inherited Create;
  FMaxValue := 0;
  FMinValue := 0;
  FAutoMaxMinValue := True;
  FOwner := AOwner;
  FTextColor := clNone;
end;

destructor TGoogleChartAxis.Destroy;
begin
  inherited;
end;

function TGoogleChartAxis.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TGoogleChartAxis.SetAutoMaxMinValue(const Value: Boolean);
begin
  if FAutoMaxMinValue <> Value then
  begin
    FAutoMaxMinValue := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAxis.SetMaxValue(const Value: Integer);
begin
  if FMaxValue <> Value then
  begin
    FMaxValue := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TGoogleChartAxis.SetMinValue(const Value: Integer);
begin
  if FMinValue <> Value then
  begin
    FMinValue := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;


function TGoogleChart.BuildColor(PropertyName: string; Color: TGoogleChartColor; First: Boolean): string;
begin
  if not First then
    result := result + ', ';
  result := result + '"' + propertyName + '": {';
  result := result + '"fill": "' + Color.Fill + '"';
  result := result + ', "stroke": "' + Color.Stroke + '"';
  if Color.StrokeWidth <>  '' then
    result := result + ', "strokeWidth": ' + Color.StrokeWidth;
  result := result + '}';
end;

{$HINTS OFF}
procedure TGoogleChart.UpdateElement;
var
  id: string;
  w,h: string;
  ct: string;
  seriesdata: TJSArray;
  pair: TJSArray;
  annotation: TJSObject;
  i, j: integer;
  hasColor: Boolean;
  pointCount: Integer;
  colors: string;
  options: TJSObject;
  soptions: string;
  soBGColor: string;
  soBorderColor: string;
  soShape: string;
  soType: string;
//  apikey: string;
  ro: TGoogleChartOptions;
  firstEl: boolean;
  areaTop: string;
  areaLeft: string;
  areaWidth: string;
  areaHeight: string;
begin
  inherited;

  if IsUpdating then
    Exit;

  if (csDestroying in ComponentState) then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if (csDesigning in ComponentState) and (Series.Count = 0) then
    begin
      TJSHTMLElement(ElementHandle).style.setProperty('display','table');
      TJSHTMLElement(ElementHandle).style.setProperty('background-color','silver');
    end;
  end
  else
    Exit;

  if Series.Count = 0 then
    Exit;

  //Fix: When running in release mode with Optimization set to True
  //Event handlers are only referenced inside an asm block
  if 1 < 0 then
  begin
    HandleLoaded(nil);
    HandleSelect(0,0);
  end;

  //AddControlScript('https://www.gstatic.com/charts/loader.js');

  id := GetID;
  w := inttostr(Width);
  h := inttostr(Height);
  ct := 'bar';
  hasColor := False;
  pointCount := 0;
//  apikey := MapsAPIKey;

  seriesdata := TJSArray.new;

  case Series[0].ChartType of
    gctArea: ct := 'AreaChart';
    gctBar: ct := 'BarChart';
    gctColumn: ct := 'ColumnChart';
    gctCandlestick: ct := 'CandlestickChart';
//    gctGauge: ct := 'Gauge';
//    gctGeo: ct := 'GeoChart';
    gctLine: ct := 'LineChart';
    gctPie: ct := 'PieChart';
    gctScatter: ct := 'ScatterChart';
    gctBubble: ct := 'BubbleChart';
    gctBubbleColor: ct := 'bubblecolor';
    gctTimeLine: ct := 'Timeline';
  end;

  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Color <> clNone then
      hasColor := true;
    if Series[I].Values.Count > PointCount then
      PointCount := Series[I].Values.Count;
  end;

  //Build Header
//    if rh then
  begin
    annotation := TJSJSON.parseObject('{ "role": "annotation" }');
    if PointCount > 0 then
    begin
      pair := TJSArray.new;

      case Series[0].ChartType of
        gctArea,
        gctBar,
        gctColumn,
        gctLine:
        begin
          pair.push('ID');
          pair.push(Series[0].Title);

          if Series.Count > 1 then
          begin
            if (Series[0].AnnotationType <> gcatNone) then
              pair.push(annotation);

            for I := 1 to Series.Count - 1 do
            begin
              pair.push(Series[I].Title);
              if (Series[I].AnnotationType <> gcatNone) then
                pair.push(annotation);
            end;
          end;
        end;

        gctBubble:
        begin
          pair.push('ID');
          pair.push('X');
          pair.push('Y');
          pair.push('Serie');
          pair.push('Size');
        end;

        gctBubbleColor:
        begin
          pair.push('ID');
          pair.push('X');
          pair.push('Y');
          pair.push('Value');
        end;

        gctCandlestick:
        begin
          pair.push('ID');
          pair.push('X');
          pair.push('Y');
          pair.push('Minimum');
          pair.push('Maximum');
          if (Series[0].AnnotationType <> gcatNone) then
            pair.push(annotation);
        end;

//        gctGauge,
        gctPie:
        begin
          pair.push('ID');
          pair.push('Value');
        end;

//        gctGeo:
//        begin
//          pair.push('Country');
//          pair.push('Value');
//        end;

        gctScatter:
        begin
          pair.push('X');
          for I := 0 to Series.Count - 1 do
          begin
            pair.push(Series[I].Title);
            if (Series[I].AnnotationType <> gcatNone) then
              pair.push(annotation);
          end;
        end;

        gctTimeline:
        begin
          pair.push('ID');
          pair.push('StartTime');
          pair.push('EndTime');
        end;
      end;

      seriesdata.push(pair);
    end;
  end;

  //Build Data
  for J := 0 to PointCount - 1 do
  begin
    pair := TJSArray.new;

    if Series[0].Values.Count > J then
      BuildChartData(Series[0], Series[0].Values[J].DataPoint, pair, true)
    else
      pair.push(nil);

    if (Series[0].ChartType in [gctBar, gctColumn, gctLine, gctArea, gctScatter]) then
    begin
      if (Series[0].AnnotationType = gcatData) then
      begin
        if Series[0].Values.Count > J then
          BuildChartData(Series[0], Series[0].Values[J].DataPoint, pair, false)
        else
          pair.push(nil);
      end
      else if (Series[0].AnnotationType = gcatText) then
      begin
        pair.push(Series[0].AnnotationText);
      end;
    end;

    for I := 1 to Series.Count - 1 do
    begin
      if Series[I].Values.Count > J then
        BuildChartData(Series[0], Series[I].Values[J].DataPoint, pair, false)
      else
        pair.push(nil);

      if (Series[0].ChartType in [gctBar, gctColumn, gctLine, gctArea, gctScatter]) then
      begin
        if (Series[I].AnnotationType = gcatData) then
        begin
          if Series[I].Values.Count > J then
            BuildChartData(Series[0], Series[I].Values[J].DataPoint, pair, false)
          else
            pair.push(nil);
        end
        else if (Series[0].AnnotationType = gcatText) then
        begin
          pair.push(Series[I].AnnotationText);
        end;
      end;
    end;

    seriesdata.push(pair);
  end;

//  OutputDebugString(TJSJSON.Stringify(seriesdata));

  if hasColor then
  begin
    for I := 0 to Series.Count - 1 do
    begin
      if I > 0 then
        colors := colors + ', ';
      if Series[I].Color <> clNone then
        colors := colors + '"' + ColorToHTML(Series[I].Color) + '"'
      else
        colors := colors + '"#000000"'
    end;
    if colors <> '' then
      colors := ', "colors": [' + colors + ']';
  end;

  //Options
  ro.Animation.Duration := IntToStr(Appearance.Animation.Duration);
  case Appearance.Animation.Easing of
    gceLinear: ro.Animation.Easing := 'linear';
    gceIn: ro.Animation.Easing := 'in';
    gceOut: ro.Animation.Easing := 'out';
    gceInAndOut: ro.Animation.Easing := 'inAndOut';
  end;
  ro.Animation.Startup := LowerCase(BoolToStr(Appearance.Animation.Startup, True));

  soBGColor := 'transparent';
  if Appearance.Background.Color <> clNone then
    soBGColor := ColorToHTML(Appearance.Background.Color);
  soBorderColor := 'transparent';
  if Appearance.Background.BorderColor <> clNone then
    soBorderColor := ColorToHTML(Appearance.Background.BorderColor);
  ro.BackgroundColor.Fill := soBGColor;
  ro.BackgroundColor.Stroke := soBorderColor;
  ro.BackgroundColor.StrokeWidth := IntToStr(Appearance.Background.BorderWidth);

  ro.Colors := colors;

  ro.EnableInteractivity := LowerCase(BoolToStr(Enabled, True));
  ro.FontSize := IntToStr(Font.Size + 3);
  ro.FontName := Font.Name;
  case Appearance.Stacked of
    gcsDisabled: ro.IsStacked := 'false';
    gcsAbsolute: ro.IsStacked := 'absolute';
    gcsPercent: ro.IsStacked := 'percent';
    gcsRelative: ro.IsStacked := 'relative';
  end;
  case Appearance.Legend.Position of
    gcpBottom: ro.Legend.Position := 'bottom';
    gcpLeft: ro.Legend.Position := 'left';
    gcpIn: ro.Legend.Position := 'int';
    gcpNone: ro.Legend.Position := 'none';
    gcpRight: ro.Legend.Position := 'right';
    gcpTop: ro.Legend.Position := 'top';
  end;

  ro.ReverseCategories := LowerCase(BoolToStr(Appearance.ReverseCategories, True));

  for I := 0 to Series.Count - 1 do
  begin
    if I > 0 then
      ro.Series := ro.Series + ', ';
    ro.Series := ro.Series + '"' + IntToStr(I) + '": {';

    sotype := '';
    case Series[I].ChartType of
      gctArea: sotype := 'area';
      gctBar: sotype := 'bars';
      gctColumn: sotype := 'bars';
      gctCandlestick: sotype := 'candlesticks';
      gctLine: sotype := 'line';
    end;

    if Series[I].ChartType in [gctLine, gctArea, gctScatter] then
    begin
      case Series[I].Line.PointShape of
        gcpsCircle: soShape := 'circle';
        gcpsTriangle: soShape := 'triangle';
        gcpsSquare: soShape := 'square';
        gcpsDiamond: soShape := 'diamond';
        gcpsStar: soShape := 'star';
        gcpsPolygon: soShape := 'polygon';
      end;
      ro.Series := ro.Series + '"lineWidth": ' + IntToStr(Series[I].Line.LineWidth) + ', "pointSize": '
        + IntToStr(Series[I].Line.PointSize) + ', "pointShape": "' + soShape + '"';
      if Series[I].Line.PointSize > 0 then
        ro.Series := ro.Series + ', "pointsVisible": true'
      else
        ro.Series := ro.Series + ', "pointsVisible": false';
      if sotype <> '' then
        ro.series := ro.series + ', '
    end;

    if sotype <> '' then
      ro.Series := ro.Series + '"type": "' + sotype + '"';
    ro.Series := ro.Series + '}';
  end;

  ro.Title := Title;

  case Appearance.Tooltip of
    gcttHover: ro.Tooltip.Trigger := 'focus';
    gcttNone: ro.Tooltip.Trigger := 'none';
    gcttSelect: ro.Tooltip.Trigger := 'selection';
  end;

  ro.Is3D := LowerCase(BoolToStr(Appearance.PieChart.Enable3D, True));
  ro.PieHole := StringReplace(FloatToStr(Appearance.PieChart.PieHole), FormatSettings.DecimalSeparator, '.', [rfReplaceAll]);
  case Appearance.PieChart.PieSliceText of
    gcptPercentage: ro.PieSliceText := 'percentage';
    gcptValue: ro.PieSliceText := 'value';
    gcptLabel: ro.PieSliceText := 'label';
    gcptNone: ro.PieSliceText := 'none';
  end;

  if Appearance.VAxis.AutoMaxMinValue then
  begin
    ro.VAxis.MaxValue := '';
    ro.VAxis.MinValue := '';
  end
  else
  begin
    ro.VAxis.MaxValue := IntToStr(Appearance.VAxis.MaxValue);
    ro.VAxis.MinValue := IntToStr(Appearance.VAxis.MinValue);
  end;

  if Appearance.HAxis.TextColor <> clNone then
    ro.VAxis.TextStyle := '"color": "'+ ColorToHTML(Appearance.VAxis.TextColor)+'"';

  if Appearance.HAxis.AutoMaxMinValue then
  begin
    ro.HAxis.MaxValue := '';
    ro.HAxis.MinValue := '';
  end
  else
  begin
    ro.HAxis.MaxValue := IntToStr(Appearance.HAxis.MaxValue);
    ro.HAxis.MinValue := IntToStr(Appearance.HAxis.MinValue);
  end;

  if Appearance.HAxis.TextColor <> clNone then
    ro.HaXis.TextStyle := '"color": "'+ ColorToHTML(Appearance.HAXis.TextColor)+'"';

  if Appearance.LineChart.CurveType = gcctFunction then
    ro.CurveType := 'function'
  else
    ro.CurveType := 'none';

  for I := 0 to Series[0].Values.Count - 1 do
  begin
    if I > 0 then
      ro.Slices := ro.Slices + ', ';
    ro.Slices := ro.Slices + '"' + IntToStr(I) + '": {';
    ro.Slices := ro.Slices + '"offset": ' + StringReplace(FloatToStr(Series[0].Values[I].DataPoint.Offset), FormatSettings.DecimalSeparator, '.', [rfReplaceAll]);
    if Series[0].Values[I].DataPoint.Color <> clNone then
      ro.Slices := ro.Slices + ', "color": "' + ColorToHTML(Series[0].Values[I].DataPoint.Color) + '"';
    ro.Slices := ro.Slices + '}';
  end;

  if Assigned(OnCustomizeChart) then
    OnCustomizeChart(Self, ro);

  soptions := '';
  if (WidthStyle <> ssAuto) then
    soptions :=  ', "width":' + w;

  if (HeightStyle <> ssAuto) then
    soptions := soptions + ', "height":' + h;

  soptions :=  '"title":"' + ro.Title + '"' + soptions + '' + ro.Colors +'';

  if (ct = 'bubblecolor') or (ct = 'BubbleChart') then
  begin
    soptions := soptions + ', "bubble": {';
    soptions := soptions + '"textStyle": {' + ro.Bubble.TextStyle + '}';
    if ro.Bubble.Opacity <> '' then
      soptions := soptions + ', "opacity": ' + ro.Bubble.Opacity;
    if ro.Bubble.Stroke <> '' then
      soptions := soptions + ', "stroke": "' + ro.Bubble.Stroke + '"';
    soptions := soptions + '}';
    if ro.SortBubbleBySize <> '' then
      soptions := soptions + ', "sortBubblesBySize": ' + ro.SortBubbleBySize;

    firstEl := True;
    soptions := soptions + ', "sizeAxis": {';
    if ro.SizeAxis.MaxSize <> '' then
    begin
      soptions := soptions + '"maxSize": ' + ro.SizeAxis.MaxSize;
      firstEl := false;
    end;
    if ro.SizeAxis.MaxValue <> '' then
    begin
      if firstEl = false then
        soptions := soptions + ', ';
      soptions := soptions + '"maxValue": ' + ro.SizeAxis.MaxValue;
      firstEl := false;
    end;
    if ro.SizeAxis.MinSize <> '' then
    begin
      if firstEl = false then
        soptions := soptions + ', ';
      soptions := soptions + '"minSize": ' + ro.SizeAxis.MinSize;
      firstEl := false;
    end;
    if ro.SizeAxis.MinValue <> '' then
    begin
      if firstEl = false then
        soptions := soptions + ', ';
      soptions := soptions + '"minValue": ' + ro.SizeAxis.MinValue;
    end;
    soptions := soptions + '}';

    soptions := soptions + ', "colorAxis": {';
    soptions := soptions + '"colors": [' + ro.ColorAxis.Colors + ']';
    soptions := soptions + ', "values": [' + ro.ColorAxis.Values + ']';
    if ro.ColorAxis.MinValue <> '' then
      soptions := soptions + ', "minValue": ' + ro.ColorAxis.MinValue;
    if ro.ColorAxis.MaxValue <> '' then
      soptions := soptions + ', "maxValue": ' + ro.ColorAxis.MaxValue;
    soptions := soptions + ', "legend": {';
    soptions := soptions + '"textStyle": {' + ro.ColorAxis.Legend.TextStyle + '}';
    if ro.ColorAxis.Legend.Position <> '' then
      soptions := soptions + ', "position": "' + ro.ColorAxis.Legend.Position + '"';
    if ro.ColorAxis.Legend.NumberFormat <> '' then
      soptions := soptions + ', "numberFormat": "' + ro.ColorAxis.Legend.NumberFormat + '"';
    soptions := soptions + '}';
    soptions := soptions + '}';
  end;

  soptions := soptions + ', "annotations": {';
  soptions := soptions + '"boxStyle": {' + ro.Annotations.BoxStyle + '}';
  if ro.Annotations.Style <> '' then
    soptions := soptions + ', "style": "' + ro.Annotations.Style + '"';
  soptions := soptions + ', "textStyle": {' + ro.Annotations.TextStyle + '}';
  if ro.Annotations.AlwaysOutside <> '' then
    soptions := soptions + ', "alwaysOutside": ' + ro.Annotations.AlwaysOutside + '';
  soptions := soptions + '}';

  if ro.AxisTitlesPosition <> '' then
    soptions := soptions + ', "axisTitlesPosition": "' + ro.AxisTitlesPosition + '"';

  if ro.DataOpacity <> '' then
    soptions := soptions + ', "dataOpacity": ' + ro.DataOpacity;

  soptions := soptions + ', "isStacked": "' + ro.IsStacked + '"';

  soptions := soptions + BuildColor('backgroundColor', ro.BackgroundColor, False);

  areaTop := 'auto';
  areaLeft := 'auto';
  areaWidth := 'auto';
  areaHeight := 'auto';

  if ro.ChartArea.Top <> '' then
    areaTop := ro.ChartArea.Top;

  if ro.ChartArea.Left <> '' then
    areaLeft := ro.ChartArea.Left;

  if ro.ChartArea.Width <> '' then
    areaWidth := ro.ChartArea.Width;

  if ro.ChartArea.Height <> '' then
    areaHeight := ro.ChartArea.Height;

  soptions := soptions + ', "chartArea": {"backgroundColor": "transparent", "top": "' + areaTop + '", "left": "' + areaLeft + '", "width": "' + areaWidth + '", "height": "' + areaHeight + '"}';

  soptions := soptions + ', "enableInteractivity": ' + ro.EnableInteractivity;

  soptions := soptions + ', "fontSize": ' + ro.FontSize
    +  ', "fontName": "' + ro.FontName + '"';

  soptions := soptions + ', "legend": {';
  soptions := soptions + '"textStyle": {' + ro.Annotations.TextStyle + '}';
  if ro.Legend.Position <> '' then
    soptions := soptions + ', "position": "' + ro.Legend.Position + '"';
  if ro.Legend.Alignment <> '' then
    soptions := soptions + ', "alignment": "' + ro.Legend.Alignment + '"';
  soptions := soptions + '}';

  if ro.ReverseCategories <> '' then
    soptions := soptions + ', "reverseCategories": ' +  ro.ReverseCategories;

  firstEL := true;
  soptions := soptions + ', "tooltip": {';
  if ro.Tooltip.IgnoreBounds <> '' then
  begin
    soptions := soptions + '"ignoreBounds": ' + ro.Tooltip.IgnoreBounds;
    firstEL := false;
  end;
  if ro.Tooltip.Trigger <> '' then
  begin
    if not firstEL then
      soptions := soptions + ', ';
    soptions := soptions + '"trigger": "' + ro.Tooltip.Trigger + '"';
    firstEL := false;
  end;
  if ro.Tooltip.ShowColorCode <> '' then
  begin
    if not firstEL then
      soptions := soptions + ', ';
    soptions := soptions + '"showColorCode": "' + ro.Tooltip.ShowColorCode + '"';
    firstEL := false;
  end;
  if ro.Tooltip.TextStyle <> '' then
  begin
    if not firstEL then
      soptions := soptions + ', ';
    soptions := soptions + '"TextStyle": {' + ro.Tooltip.TextStyle + '}';
    firstEL := false;
  end;
  soptions := soptions + '}';

  soptions := soptions + ', "series": {' + ro.Series + '}';

  if ro.Orientation <> '' then
    soptions := soptions + ', "orientation": "' + ro.Orientation + '"';

  if ro.FocusTarget <> '' then
    soptions := soptions + ', "focusTarget": "' + ro.FocusTarget + '"';

  if ro.TitlePosition <> '' then
    soptions := soptions + ', "titlePosition": "' + ro.TitlePosition + '"';

  if ro.TitleTextStyle <> '' then
    soptions := soptions + ', "titleTextStyle": {' + ro.TitleTextStyle + '}';

  if ro.VAxes <> '' then
    soptions := soptions + ', "vAxes": ' + ro.VAxes;

  if ro.TrendLines <> '' then
    soptions := soptions + ', "trendLines": {' + ro.TrendLines + '}';

  if ro.SelectionMode <> '' then
    soptions := soptions + ', "selectionMode": "' + ro.SelectionMode + '"';

  if ro.AggregationTarget <> '' then
    soptions := soptions + ', "aggregationTarget": "' + ro.AggregationTarget + '"';

  if ro.AreaOpacity <> '' then
    soptions := soptions + '"areaOpacity": ' + ro.AreaOpacity;

  if ro.InterpolateNulls <> '' then
    soptions := soptions + '"interpolateNulls": ' + ro.InterpolateNulls;

  if ct = 'CandlestickChart' then
  begin
    soptions := soptions + ', "candleStick": {';
    soptions := soptions + BuildColor('fallingColor', ro.CandleStick.FallingColor, True);
    soptions := soptions + BuildColor('risingColor', ro.CandleStick.RisingColor, False);
    if (ro.CandleStick.HollowIsRising <> '') then
      soptions := soptions + ', "hollowIsRising": ' + ro.CandleStick.HollowIsRising;
    soptions := soptions + '}';
  end;

  if ct = 'LineChart' then
  begin
    if ro.CurveType <> '' then
      soptions := soptions + ', "curveType": "' + ro.CurveType + '"';
  end;

  if ct = 'PieChart' then
  begin
    soptions := soptions + ', "is3D": ' +  ro.Is3D;
    soptions := soptions + ', "pieHole": ' +  ro.PieHole;
    soptions := soptions + ', "pieSliceText": "' + ro.PieSliceText + '"';
    soptions := soptions + ', "slices": {' + ro.Slices + '}';
    if ro.PieSliceBorderColor <> '' then
      soptions := soptions + ', "pieSliceBorderColor": "' + ro.PieSliceBorderColor + '"';
    if ro.PieSliceTextStyle <> '' then
      soptions := soptions + ', "pieSliceTextStyle": {' + ro.PieSliceTextStyle + '}';
    if ro.PieStartAngle <> '' then
      soptions := soptions + ', "piestartAngle": ' + ro.PieStartAngle;
    if ro.PieResidueSliceColor <> '' then
      soptions := soptions + ', "pieResidueSliceColor": "' + ro.PieResidueSliceColor + '"';
    if ro.PieResidueSliceLabel <> '' then
      soptions := soptions + ', "pieResidueSliceLabel": "' + ro.PieResidueSliceLabel + '"';
    if ro.SliceVisibilityTreshold <> '' then
      soptions := soptions + ', "sliceVisibilityTreshold": ' + ro.SliceVisibilityTreshold;
  end
  else
  begin
    soptions := soptions + ', "animation": {"startup": ' + ro.Animation.Startup
      + ', "duration": ' + ro.Animation.Duration
      + ', "easing": "' + ro.Animation.Easing
      + '"}';

    soptions := soptions + ', "vAxis": {';
    soptions := soptions + '"viewWindow": {';
    if (ro.VAxis.ViewWindow.Min <> '') then
      soptions := soptions + '"min": ' + ro.VAxis.ViewWindow.Min;
    if (ro.VAxis.ViewWindow.Min <> '') and (ro.VAxis.ViewWindow.Max <> '') then
      soptions := soptions + ', ';
    if (ro.VAxis.ViewWindow.Max <> '') then
      soptions := soptions + '"max": ' + ro.VAxis.ViewWindow.Max;
    soptions := soptions + '}';
    if (ro.VAxis.BaseLine <> '') then
      soptions := soptions + ', "baseline": ' + ro.vAxis.BaseLine;
    if (ro.VAxis.BaseLineColor <> '') then
      soptions := soptions + ', "baselineColor": "' + ro.vAxis.BaseLineColor + '"';
    if (ro.VAxis.Direction <> '') then
      soptions := soptions + ', "direction": ' + ro.vAxis.Direction;
    if (ro.VAxis.Format <> '') then
      soptions := soptions + ', "format": "' + ro.vAxis.Format + '"';
    if (ro.VAxis.GridLines <> '') then
    soptions := soptions + ', "gridlines": {' + ro.vAxis.GridLines + '}';
    if (ro.VAxis.MinorGridLines <> '') then
    soptions := soptions + ', "minorGridlines": {' + ro.vAxis.MinorGridLines + '}';
    if (ro.VAxis.TextPosition <> '') then
      soptions := soptions + ', "textPosition": "' + ro.vAxis.TextPosition + '"';
    soptions := soptions + ', "textStyle": {' + ro.vAxis.TextStyle + '}';
    if (ro.VAxis.Ticks <> '') then
      soptions := soptions + ', "ticks": [' + ro.vAxis.Ticks + ']';
    if (ro.VAxis.Title <> '') then
      soptions := soptions + ', "title": "' + ro.vAxis.Title + '"';
    soptions := soptions + ', "titleTextStyle": {' + ro.vAxis.TitleTextStyle + '}';
    if (ro.VAxis.SlantedText <> '') then
      soptions := soptions + ', "slantedText": ' + ro.vAxis.SlantedText;
    if (ro.VAxis.SlantedTextAngle <> '') then
      soptions := soptions + ', "slantedTextAngle": ' + ro.vAxis.SlantedTextAngle;
    if (ro.VAxis.MaxValue <> '') then
      soptions := soptions + ', "maxValue": ' + ro.vAxis.maxValue;
    if (ro.VAxis.MinValue <> '') then
      soptions := soptions + ', "minValue": ' + ro.vAxis.minValue;
    if (ro.VAxis.ViewWindowMode <> '') then
      soptions := soptions + ', "viewWindowMode": "' + ro.vAxis.ViewWindowMode + '"';
    soptions := soptions + '}';

    soptions := soptions + ', "hAxis": {';
    soptions := soptions + '"viewWindow": {';
    if (ro.HAxis.ViewWindow.Min <> '') then
      soptions := soptions + '"min": ' + ro.HAxis.ViewWindow.Min;
    if (ro.HAxis.ViewWindow.Min <> '') and (ro.HAxis.ViewWindow.Max <> '') then
      soptions := soptions + ', ';
    if (ro.HAxis.ViewWindow.Max <> '') then
      soptions := soptions + '"max": ' + ro.HAxis.ViewWindow.Max;
    soptions := soptions + '}';
    if (ro.HAxis.BaseLine <> '') then
      soptions := soptions + ', "baseline": ' + ro.hAxis.BaseLine;
    if (ro.HAxis.BaseLineColor <> '') then
      soptions := soptions + ', "baselineColor": "' + ro.hAxis.BaseLineColor + '"';
    if (ro.HAxis.Direction <> '') then
      soptions := soptions + ', "direction": ' + ro.hAxis.Direction;
    if (ro.HAxis.Format <> '') then
      soptions := soptions + ', "format": "' + ro.hAxis.Format + '"';
    if (ro.HAxis.GridLines <> '') then
    soptions := soptions + ', "gridlines": {' + ro.hAxis.GridLines + '}';
    if (ro.HAxis.MinorGridLines <> '') then
    soptions := soptions + ', "minorGridlines": {' + ro.hAxis.MinorGridLines + '}';
    if (ro.HAxis.TextPosition <> '') then
      soptions := soptions + ', "textPosition": "' + ro.hAxis.TextPosition + '"';
    soptions := soptions + ', "textStyle": {' + ro.hAxis.TextStyle + '}';

    if (ro.HAxis.Ticks <> '') then
      soptions := soptions + ', "ticks": [' + ro.hAxis.Ticks + ']';
    if (ro.HAxis.Title <> '') then
      soptions := soptions + ', "title": "' + ro.hAxis.Title + '"';
    soptions := soptions + ', "titleTextStyle": {' + ro.hAxis.TitleTextStyle + '}';
    if (ro.HAxis.SlantedText <> '') then
      soptions := soptions + ', "slantedText": ' + ro.hAxis.SlantedText;
    if (ro.HAxis.SlantedTextAngle <> '') then
      soptions := soptions + ', "slantedTextAngle": ' + ro.hAxis.SlantedTextAngle;
    if (ro.HAxis.MaxValue <> '') then
      soptions := soptions + ', "maxValue": ' + ro.hAxis.maxValue;
    if (ro.HAxis.MinValue <> '') then
      soptions := soptions + ', "minValue": ' + ro.hAxis.minValue;
    if (ro.HAxis.ViewWindowMode <> '') then
      soptions := soptions + ', "viewWindowMode": "' + ro.hAxis.ViewWindowMode + '"';
    soptions := soptions + '}';
  end;

  for I := 0 to FOptions.Count - 1 do
  begin
    soptions := soptions + ', ' + FOptions[I];
  end;

  soptions := '{' + soptions + '}';

  if Assigned(OnCustomizeChartJSON) then
    OnCustomizeChartJSON(Self, soptions);

//  OutputDebugstring(soptions);

  try
    options := TJSJSON.parseObject(soptions);
  except
    Exit;
  end;

  asm
    if ((google == null) || (google == undefined))
      return;

    google.charts.load('51');
    var cl = this;

    google.charts.setOnLoadCallback(function () { drawChart(cl); });

    function drawChart(pclass)
    {
      if (seriesdata != null)
      {
        var data = google.visualization.arrayToDataTable(seriesdata, false);
        pclass.FChartData = data;

        if (ct == 'bubblecolor')
          ct = 'BubbleChart';

        var wrapper = new google.visualization.ChartWrapper({
          chartType: ct,
          dataTable: data,
          options: options,
          containerId: pclass.FID
        });

        pclass.FChartObject = wrapper;

        google.visualization.events.addListener(wrapper, 'ready', onReady);

        wrapper.draw();

        function onReady() {
          google.visualization.events.addListener(wrapper.getChart(), 'select', onSelect);
          pclass.HandleLoaded(null);
        }

        function onSelect() {
          var selection = wrapper.getChart().getSelection();
          if (selection.length > 0)
          {
            var row = 0;
            var col = 0;
            if (selection[0].row)
              row = selection[0].row;
            if (selection[0].column)
              col = selection[0].column;
            pclass.HandleSelect(row, col);
          }
        }
      }
    }
  end;
end;
{$HINTS ON}


end.

